<template>
  <PriceDetailV2 
    v-if="orderConfirmDiscountSwitchByAbt" 
    :pre-pay-un-pay-params="prePayUnPayParams"
  />
  <PriceDetail 
    v-else 
    :pre-pay-un-pay-params="prePayUnPayParams"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import PriceDetail from './PriceDetail.vue'
import PriceDetailV2 from './PriceDetailV2.vue'

defineProps({
  prePayUnPayParams: {
    type: Object,
    default: () => ({})
  }
})

const store = useStore()

const orderConfirmDiscountSwitchByAbt = computed(() => store.getters.orderConfirmDiscountSwitchByAbt)
</script>
