<template>
  <div class="shipping-address-card">
    <div
      class="shipping-address-card__header"
      tabindex="0"
    >
      <h4 class="shipping-address-card__title">
        {{ title || language.SHEIN_KEY_PC_14720 }}
      </h4>
      <div
        v-if="isShowChangeBtn"
        class="shipping-address-card__header-btn"
        @click="chooseAddressHandler"
      >
        <span>{{ language.SHEIN_KEY_PC_25884 }}</span>
        <sui_icon_more_right_14px
          color="#222222"
          size="14px"
        />
      </div>
    </div>

    <div
      v-if="checkout.default_address"
      class="shipping-address-card__body"
    >
      <div class="shipping-address-item">
        <div class="shipping-address-item__content">
          <div
            class="shipping-address-item__header"
            tabindex="0"
          >
            <h5 class="shipping-address-item__name not-fsp-element">
              {{ sortAddressCardInfo({ detail: checkout.default_address }) }}
            </h5>
            <div class="shipping-address-item__phone">

              {{ checkout.default_address.tel }}
            </div>
          </div>
          <p
            v-if="checkout.default_address.countryId == '108'"
            class="shipping-address-item__address"
            tabindex="0"
          >
            〒 {{ checkout.default_address.postcode }}
          </p>
          <p
            v-else
            class="shipping-address-item__address"
            tabindex="0"
          >
            {{ checkout.default_address.address1 }}
            {{ checkout.default_address.address2 }}
            <template
              v-if="
                checkout.default_address.countryId == '209' &&
                  checkout.default_address.type == '3'
              "
            >
              ({{ language.SHEIN_KEY_PC_17772 }})
            </template>
          </p>
          <p
            v-if="checkout.default_address.countryId == '108'"
            class="shipping-address-item__address"
            tabindex="0"
          >
            {{ checkout.default_address.countryName }}
            {{ checkout.default_address.state }}
            {{ checkout.default_address.city }}
            {{ checkout.default_address.district }}
            {{ checkout.default_address.street }}
            {{ checkout.default_address.address1 }} {{ checkout.default_address.address2 }}
            <s-label
              v-if="checkout.default_address.isDefault == 1"
              class="shipping-address-item__tag"
              type="success"
            >
              {{ language.SHEIN_KEY_PC_14782 }}
            </s-label>
          </p>
          <p
            v-else
            class="shipping-address-item__address"
            tabindex="0"
          >
            {{ checkout.default_address.street }}
            {{
              [checkout.default_address.district, secondLanguage.district]
                .filter(Boolean)
                .join('/')
            }}
            {{
              [checkout.default_address.city, secondLanguage.city]
                .filter(Boolean)
                .join('/')
            }}
            {{
              [checkout.default_address.state, secondLanguage.state]
                .filter(Boolean)
                .join('/')
            }}
            {{ checkout.default_address.countryName }}
            {{ checkout.default_address.postcode }}

            <s-label
              v-if="checkout.default_address.isDefault == 1"
              class="shipping-address-item__tag"
              type="success"
            >
              {{ language.SHEIN_KEY_PC_14782 }}
            </s-label>
          </p>
          <!-- 地址错误提示 -->
          <div
            v-if="
              (checkout.default_address.countryId == '178' &&
                checkout.default_address.fatherName == '') ||
                checkout.default_address.editHint == 1
            "
            class="shipping-address-item__error-tips"
            tabindex="0"
          >
            {{ language.SHEIN_KEY_PC_14804 }}
          </div>
          <div
            v-if="sensitiveNote"
            class="shipping-address-item__error-tips"
            tabindex="0"
          >
            {{ sensitiveNote }}
          </div>
        </div>

        <s-button
          :type="['H28PX']"
          class="shipping-address-item__edit"
          @click="clickEditAddress(-1, checkout.default_address)"
        >
          {{ language.SHEIN_KEY_PC_17140 }}
        </s-button>
      </div>

      <ClientOnly>
        <address-title-tips
          v-if="status.adrTitleTipsShow"
          :language="language"
          :adr-name-verified-title-tip="adrNameVerifiedTitleTip"
          :status="status"
          :checkout="checkout"
          :kr-copywrite-tip="krCopywriteTip"
          @click-tw-jump-url="clickTwJumpUrl"
          @click-address-tips="clickAddressTips"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import AddressTitleTips from './AddressTitleTips.vue'

import { sortAddressCardInfo } from 'public/src/pages/components/address/config'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { sui_icon_more_right_14px } from '@shein-aidc/icon-vue3'
import { ClientOnly } from '@sheinfe/vue-client-only'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'ShippingAddressCard',
  emits: [
    'click-tw-jump-url',
    'click-address-tips',
    'click-change-btn'
  ],
  components: {
    AddressTitleTips,
    sui_icon_more_right_14px,
    ClientOnly,
  },
  props: {
    adrNameVerifiedTitleTip: {
      type: String,
      default: ''
    },
    status: {
      type: Object,
      default: () => {}
    },
    checkout: {
      type: Object,
      default: () => {}
    },
    secondLanguage: {
      type: Object,
      default: () => {}
    },
    krCopywriteTip: {
      type: String,
      default: ''
    },
    sourceGray: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    // 是否展示change按钮
    isShowChangeBtn: {
      type: Boolean,
      default: true
    },
    isRiskAddress: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    sensitiveNote () {
      const sensitiveRuleVoList = this.checkout?.default_address?.sensitiveRuleVoList || []
      if (!sensitiveRuleVoList.length) {
        return ''
      }
      return sensitiveRuleVoList?.[0]?.rule_content || ''
    }
  },
  mounted() {
    // 首次显示的地址是风控地址，需要上报埋点
    if (this.status.hasAddress && this.isRiskAddress) {
      daEventCenter.triggerNotice({
        daId: '1-11-1-169',
        extraData: {
          scenes: 'address_risk',
          type: '-',
        }
      })
    }
  },
  methods: {
    ...mapMutations([
      'assignState', 
    ]),
    sortAddressCardInfo,
    clickTwJumpUrl(event) {
      this.$emit('click-tw-jump-url', event)
    },
    clickAddressTips(event) {
      this.$emit('click-address-tips', event)
    },
    chooseAddressHandler() {
      this.assignState({
        showAddressDialog: true
      })
      daEventCenter.triggerNotice({
        daId: '1-11-1-129',
        extraData: {
          address_risk: this.isRiskAddress
        }
      })
      this.$emit('click-change-btn')
    },
    clickEditAddress(index, address) {
      window.checkoutEventCenter.emit('click-edit-address', { index, address })
    },
  },
}
</script>

<style lang="less">
.shipping-address-card {
  padding: 16px;
  color: @sui_color_gray_dark1;
  background: #fff;
  margin-bottom: 10px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &-btn {
      font-family: Arial;
      font-size: 13px;
      line-height: 15px;
      font-weight: 700;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-family: Arial Black;
    text-transform: capitalize;

    & when (@IS_RW) {
      font-family: Adieu;
    }
  }

  &__body {
    padding: 12px 12px 12px 16px;
    border: 1px solid #e5e5e5;
    border-left: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background-image: url('/she_dist/images/border-e8fd9a0d48.png');
    }
  }

  .address-title-tips-screen-efficiency {
    margin-top: 11px;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
    color: #666;
    background: #f6f6f6;

    a {
      color: #1860a7;
    }
  }
}
.is-left {
  .shipping-address-card {
    margin-bottom: 0;
  }
}

.shipping-address-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-all;

  &__content {
    flex: auto;
    overflow: hidden;
    margin-right: 12px;
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }

  &__phone {
    font-size: 16px;
    line-height: 18px;
    white-space: nowrap;
    margin-left: 4px;
  }

  &__address {
    font-size: 13px;
    line-height: 15px;
    margin-top: 4px;
  }

  &__tag {
    text-transform: capitalize;
    margin-left: 4px;
  }

  &__error-tips {
    font-size: 13px;
    line-height: 15px;
    color: #bf4123;
    margin-top: 11px;
  }
  &__edit {
    flex-shrink: 0;
  }
}
</style>
