<template>
  <div
    class="checkout-summary-price price-detail"
    tabindex="0"
  >
    <!-- 无地址展示购物车totalPrice字段 -->
    <template v-if="caculateData.address_id == 0">
      <!-- 原价retail price 和 30天最低价 Lowest Price，两者是互斥展示的 -->
      <div
        v-if="showNotAddressRetailPrice"
        class="price-detail__item"
      >
        <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_14749 }}:</span>
        <del class="price-detail__item-right origin-price">{{
          caculateInfo.originPrice.amountWithSymbol
        }}</del>
      </div>
      <!-- 30天最低价 Lowest Price -->
      <template v-else-if="showLowestPrice">
        <div class="price-detail__item">
          <span class="price-detail__item-left">{{ 
            isSuggestedInDe ? language.SHEIN_KEY_PC_31068 : language.SHEIN_KEY_PC_30549 
          }}:</span>
          <span class="price-detail__item-right">{{ caculateInfo.crossed_price.amountWithSymbol }}</span>
        </div>
        <!-- 德国合规30天最低价提示 -->
        <div 
          v-if="
            isSuggestedInDe &&
              +caculateInfo?.suggested_sale_price?.amount > 0
          "
          class="price-detail__tips"
        >
          <span>{{ language.SHEIN_KEY_PC_31069 }}&nbsp;</span>
          <del>{{ caculateInfo.suggested_sale_price.amountWithSymbol }}</del>
        </div>
      </template>

      <!-- 总价 subtotal -->
      <div
        v-if="caculateInfo.totalPrice"
        class="price-detail__item price-detail__item-total"
      >
        <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_14750 }}:</span>
        <span class="price-detail__item-right price-detail__item-right-total">{{
          caculateInfo.totalPrice.amountWithSymbol
        }}</span>
      </div>
    </template>
    <template v-else>
      <!-- 原价retail price 和 30天最低价 Lowest Price，两者是互斥展示的 -->
      <!-- 原价 retail price -->
      <div
        v-if="showPromotionInfo && !isSuggested"
        class="price-detail__item"
      >
        <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_14749 }}:</span>
        <span class="price-detail__item-right">{{ caculateInfo.originPrice.amountWithSymbol }}</span>
      </div>
      <!-- 30天最低价 Lowest Price -->
      <template v-else-if="showLowestPrice">
        <div class="price-detail__item">
          <span class="price-detail__item-left">{{ 
            isSuggestedInDe ? language.SHEIN_KEY_PC_31068 : language.SHEIN_KEY_PC_30549 
          }}:</span>
          <span class="price-detail__item-right">{{ caculateInfo.crossed_price.amountWithSymbol }}</span>
        </div>
        <!-- 德国合规30天最低价提示 -->
        <div 
          v-if="
            isSuggestedInDe &&
              +caculateInfo?.suggested_sale_price?.amount > 0
          "
          class="price-detail__tips"
        >
          <span>{{ language.SHEIN_KEY_PC_31069 }}&nbsp;</span>
          <del>{{ caculateInfo.suggested_sale_price.amountWithSymbol }}</del>
        </div>
      </template>

      <!-- 促销活动优惠总金额 promotions-->
      <PromotionInfo v-if="showPromotionInfo" />

      <!-- 总价 subtotal -->
      <div :class="{ 'subtotal-bottom-border': showPromotionInfo }">
        <div 
          v-if="caculateInfo.newSubTotal"
          class="price-detail__item" 
          :class="{ 'summary-item__subtotal': hasSubTotalDetails }"
          @click="hasSubTotalDetails && (showSubTotalDetails = !showSubTotalDetails)"
        >
          <span class="price-detail__item-left">
            {{ language.SHEIN_KEY_PC_14750 }}({{ template(cartNumTotal(checkout.results.carts.carts), language.SHEIN_KEY_PC_29868) }}):
          </span>
          <span class="price-detail__item-right">
            <span>{{ caculateInfo.newSubTotal.amountWithSymbol }}</span>
            <component
              :is="showSubTotalDetails ? 'sui_icon_more_up_12px_1' : 'sui_icon_more_down_12px_1'"
              v-if="hasSubTotalDetails"
              :is-responsive-name="true"
              size="12px"
              color="#000000"
            />
          </span>
        </div>
        <!-- subTotal details -->
        <ItemDetails
          v-if="showSubTotalDetails"
          :details="getItemDetails(PriceItemType.subTotal)"
        />
      </div>

      <!-- 需要根据值在方法中动态调整顺序的部分 -->
      <div
        ref="priceDetailSortRef"
        :class="{ 'price-detail-sort': showPromotionInfo }"
      >
        <!-- 运费 shipping fee-->
        <div :identification="priceDetailNameMap.shippingFee">
          <div 
            v-if="caculateInfo.actual_shipping_price"
            class="price-detail__item" 
            :class="{ 'summary-item__subtotal': hasShippingFeeDetails }"
            @click="hasShippingFeeDetails && (showShippingFeeDetails = !showShippingFeeDetails)"
          >
            <span class="price-detail__item-left">
              {{ language.SHEIN_KEY_PC_14751 }}:
              <template v-if="showShippingFeeTips">
                <sui_icon_doubt_14px_1 
                  size="15px" 
                  color="#767676"
                  @click="clickShippingFee"
                />
              </template>
              <template v-else-if="mallCartsList.length > 1">
                <s-popover
                  class="summary-popover"
                  :hide-close-icon="true"
                  placemen="bottom"
                  trigger="hover"
                  :content="language.SHEIN_KEY_PC_22202"
                >
                  <template #reference>
                    <sui_icon_doubt_14px_1 
                      size="15px" 
                      color="#767676"
                    />
                  </template>
                </s-popover>
              </template>
            </span>
            <span
              class="price-detail__item-right"
              :class="{'price-prime': isUsePrimeCoupon }"
            >
              <del
                v-if="!isSuggested && isShowShipOrigin"
                class="origin-price"
              >{{ caculateInfo.origin_shipping_price.amountWithSymbol }}</del>
              <sui_icon_club_logo_fill_15px
                v-if="isUsePrimeCoupon"
                class="price-detail__item-right-icon"
                size="15px" 
                color="#c96e3f"
              />
              <template v-if="isFreePrice">
                <em
                  class="free-price"
                  :class="{'free-prime-color': isUsePrimeCoupon}"
                >{{ language.SHEIN_KEY_PC_24091 }}</em>
              </template>
              <template v-else>
                {{ caculateInfo.actual_shipping_price.amountWithSymbol }}
              </template>
              <component
                :is="showShippingFeeDetails ? 'sui_icon_more_up_12px_1' : 'sui_icon_more_down_12px_1'"
                v-if="hasShippingFeeDetails"
                :is-responsive-name="true"
                size="12px"
                color="#000000"
              />
            </span>
          </div>
          <!-- Shipping fee details -->
          <ItemDetails
            v-if="showShippingFeeDetails"
            :details="getItemDetails(PriceItemType.shippingFee)"
          />
        </div>

        <!-- qs运费 QS fee-->
        <div
          v-if="caculateInfo.qs_shipping_price && +caculateInfo.qs_shipping_price.amount > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.QSFee"
        >
          <span class="price-detail__item-left">
            {{ language.SHEIN_KEY_PC_24989 }}
            <s-popover
              v-if="qsFreightTips"
              class="summary-popover"
              :hide-close-icon="true"
              placemen="bottom"
              trigger="hover"
            >
              <div v-html="qsFreightTips"></div>
              <template #reference>
                <sui_icon_doubt_14px_1 
                  size="15px" 
                  color="#767676"
                />
              </template>
            </s-popover>
          </span>
          <span class="price-detail__item-right">
            {{ caculateInfo.qs_shipping_price.amountWithSymbol }}
          </span>
        </div>

        <!-- 超省卡开通费用 SHEIN Saver Fee-->
        <div 
          v-if="caculateInfo.save_card_price && +caculateInfo.save_card_price.amount > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.SHEINSaverFee"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_27044 }}</span>
          <span class="price-detail__item-right">{{ caculateInfo.save_card_price.amountWithSymbol }}</span>
        </div>

        <!-- 付费会员 SHEIN CLUB Fee -->
        <div
          v-if="caculateInfo.prime_price && +caculateInfo.prime_price.amount > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.SHEINCLUBFee"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_22967 }}</span>
          <span class="price-detail__item-right">
            <template v-if="caculateInfo.prime_price_discount && +caculateInfo.prime_price_discount.amount > 0">
              <del
                v-if="!isSuggested"
                class="origin-price"
              >{{ caculateInfo.origin_prime_price.amountWithSymbol }}</del>
              <span class="price-prime">
                <primeVipIcon :is-middle="1" />
                <span class="price-detail__item-right-text">{{ caculateInfo.prime_price.amountWithSymbol }}</span>
              </span>
            </template>
            <template v-else>
              <span class="price-detail__item-right-text">{{ caculateInfo.prime_price.amountWithSymbol }}</span>
            </template>
          </span>
        </div>

        <!-- 美国CO消费税 零售送货费 retail delivery fee-->
        <div
          v-if="caculateInfo.retail_delivery_fee && parseFloat(caculateInfo.retail_delivery_fee.amount) > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.retailDeliveryFee"
        >
          <span class="price-detail__item-left">
            {{ RDFTitle }}
            <s-popover
              :content="RDFPopuText"
              trigger="hover"
              placemen="bottom"
            >
              <template #reference>
                <sui_icon_doubt_14px_1 
                  size="15px" 
                  color="#767676"
                />
              </template>
            </s-popover>
          </span>
          <span class="price-detail__item-right">{{ caculateInfo.retail_delivery_fee.amountWithSymbol }}</span>
        </div>

        <!-- 运费险退货险保价险 insurance -->
        <div
          v-if="showInsurancePrice.length && caculateInfo.insurancePrice"
          class="price-detail__item"
          :identification="priceDetailNameMap.insurance"
        >
          <span class="price-detail__item-left">{{ showReturnInsurance }}:</span>
          <span :class="['price-detail__item-right', {'sui-label__success':isFreeInfo.isFREE}]">
            {{ isFreeInfo.isFREE == 'FREE' ? language.SHEIN_KEY_PC_25900 : caculateInfo.insurancePrice.amountWithSymbol }}
          </span>
        </div>

        <!-- 处置费 Handling fee -->
        <div
          v-if="caculateInfo.handling_fee && +caculateInfo.handling_fee.amount > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.handingFee"
        >
          <div class="price-detail__item-left">
            <span>{{ language.SHEIN_KEY_PC_19486 }}:</span>
            <s-popover
              v-if="getHandlingDescText"
              class="summary-popover"
              :hide-close-icon="true"
              placemen="bottom"
              trigger="hover"
            >
              <div v-html="getHandlingDescText"></div>
              <template #reference>
                <sui_icon_doubt_14px_1 
                  size="15px" 
                  color="#767676"
                />
              </template>
            </s-popover>
          </div>
          <span class="price-detail__item-right">{{ caculateInfo.handling_fee.amountWithSymbol }}</span>
        </div>

        <!-- COD COD fee -->
        <div
          v-if="codPrice"
          class="price-detail__item"
          :identification="priceDetailNameMap.CODFee"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_14765 }}</span>
          <span
            class="price-detail__item-right"
            v-html="codPrice"
          ></span>
        </div>

        <!-- token 卡 bin 优惠 -->
        <div 
          v-if="(isShowTokenBinDiscountBlock && (isRouterPay || isInstallmentPay) && tokenBinDiscountType !== 3) || isShowCardBinDiscountDescInPriceDetail"
          class="price-detail__item"
          tabindex="0"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_25758 }}</span>
          <code
            class="price-detail__item-right"
          >
            <em class="price-detail__item-right-discount">{{ resultTokenBinDiscountDesc }}</em>
          </code>
        </div>
        
        <!-- 优惠金额券 优惠免邮券 抵扣金额 Discount-->
        <CouponDiscountInfo
          v-if="showDiscount"
          :identification="priceDetailNameMap.discount"
        />

        <!-- 积分 SHEIN Points -->
        <div
          v-if="caculateInfo.pointPrice && +caculateInfo.pointPrice.amount > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.SHEINPoints"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_14707 }}:</span>
          <span class="price-detail__item-right price-detail__item-right-discount">-{{ caculateInfo.pointPrice.amountWithSymbol }}</span>
        </div>

        <!-- 付费会员 商品优惠价格 product discount-->
        <div
          v-if="caculateInfo.prime_deduce_price && +caculateInfo.prime_deduce_price.amount > 0"
          :identification="priceDetailNameMap.productDiscount"
        >
          <div class="price-detail__item">
            <span class="price-detail__item-left prime-max">
              {{ language.SHEIN_KEY_PC_22970 }}
              <s-popover
                :hide-close-icon="true"
                placemen="bottom"
                trigger="hover"
                :content="primeDeduceTips"
              >
                <template #reference>
                  <sui_icon_doubt_14px_1 
                    size="15px" 
                    color="#767676"
                  />
                </template>
              </s-popover>
            </span>
            <span class="price-detail__item-right prime-color">
              <primeVipIcon :is-middle="1" />
              <span class="price-detail__item-right-discount">-{{ caculateInfo.prime_deduce_price.amountWithSymbol }}</span>
            </span>
          </div>
          <div
            v-if="primeDiscountTotal"
            class="product-discount-info"
          >
            {{ primeDiscountTotal }}
          </div>
        </div>

        <!-- 支付方式优惠 在线优惠 随机立减(2)在未支付情况下不展示优惠 payment method discount-->
        <div
          v-if="caculateInfo.onlinePayDiscountInfo &&
            caculateInfo.onlinePayDiscountInfo.onlinePayDiscountInfo &&
            caculateInfo.onlinePayDiscountInfo.onlinePayDiscountInfo.isEnjoyedDiscount == 1 &&
            caculateInfo.onlinePayDiscountInfo.onlinePayDiscountInfo.discountType != 2"
          class="price-detail__item"
          :identification="priceDetailNameMap.paymentMethodDiscount"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_25757 }}</span>
          <span class="price-detail__item-right price-detail__item-right-discount">-{{
            caculateInfo.onlinePayDiscountInfo.onlinePayDiscountInfo.discountPrice
              .amountWithSymbol
          }}</span>
        </div>

        <!-- 钱包 Wallet Credit -->
        <div
          v-if="caculateInfo.walletPrice && +caculateInfo.walletPrice.amount > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.walletCredit"
        >
          <span class="price-detail__item-left">
            {{ language.SHEIN_KEY_PC_18434.replace(':', '') }}
          </span>
          <span class="price-detail__item-right price-detail__item-right-discount">-{{ caculateInfo.walletPrice.amountWithSymbol }}</span>
        </div>

        <!-- 礼品卡扣除 gift card deduction -->
        <div
          v-if="caculateInfo.giftcard && caculateInfo.giftcard.giftCardUsedPrice && caculateInfo.giftcard.giftCardUsedCurrencyAmount > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.giftCardDeduction"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_15587 }}:</span>
          <span class="price-detail__item-right price-detail__item-right-discount">-{{ caculateInfo.giftcard.giftCardUsedPrice.amountWithSymbol }}</span>
        </div>

        <!-- 增值税 add tax-->
        <div
          v-if="caculateInfo.taxEnabled == 1"
          class="price-detail__item"
          :identification="priceDetailNameMap.addTax"
        >
          <span class="price-detail__item-left">
            <template v-if="caculateInfo.isReachTax == 0">{{ language.SHEIN_KEY_PC_14792 }}:</template>
            <template v-else>{{ language.SHEIN_KEY_PC_14793 }}:</template>
            <s-popover
              :hide-close-icon="true"
              placemen="bottom"
              trigger="hover"
              :content="language.SHEIN_KEY_PC_14794"
            >
              <template #reference>
                <sui_icon_doubt_14px_1 
                  size="15px" 
                  color="#767676"
                />
              </template>
            </s-popover>
          </span>
          <span class="price-detail__item-right">{{ caculateInfo.warPrice.amountWithSymbol }}</span>
        </div>

        <!-- 推荐折扣 Referral Discount -->
        <div
          v-if="caculateInfo.extraPromotion &&
            caculateInfo.extraPromotion.marketFission &&
            caculateInfo.extraPromotion.marketFission.isValidated == 1 &&
            caculateInfo.extraPromotion.marketFission.amount &&
            caculateInfo.extraPromotion.marketFission.amount.amount > 0"
          class="price-detail__item"
          :identification="priceDetailNameMap.referralDiscount"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_16286 }}:</span>
          <span class="price-detail__item-right price-detail__item-right-discount">-{{
            caculateInfo.extraPromotion.marketFission.amount.amountWithSymbol
          }}</span>
        </div>

        <div
          v-if="isInstallmentPay && installments"
          class="price-detail__item"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_17705 }}:</span>
          <span class="price-detail__item-right price-detail__item-right-discount">{{ installments }}</span>
        </div>
        <div
          v-if="isInstallmentPay && installmentFee"
          class="price-detail__item"
        >
          <span class="price-detail__item-left">{{ language.SHEIN_KEY_PC_16443 }}:</span>
          <span class="price-detail__item-right price-detail__item-right-discount">{{ installmentFee }}</span>
        </div>
        
        <!-- 税费有封装的组件OrderSummaryTaxes，但是由于需要参与排序，因此单独抽离出来 -->
        <!-- 巴西税费 个人税费 -->
        <OrderSummaryMultiTax
          v-if="isBrOrderInSheinWithMultiTax && personalTaxes && personalTaxes.length"
          :taxes="personalTaxes"
          :language="language"
          :is-multi-tax="isMultiTaxCountry"
          :is-show-ca-gst="isShowCaGST"
          :part-order-info="partOrderInfo"
          :taxes-m-y-info="taxesMYInfo"
        />
        <!-- 非巴西税费 个人税费 -->
        <OrderSummaryMultiTax 
          v-if="showNotBrOrderWithMultiTax"
          :taxes="personalTaxes"
          :language="language"
          :is-multi-tax="isMultiTaxCountry"
          :is-show-ca-gst="isShowCaGST"
          :part-order-info="partOrderInfo"
          :taxes-m-y-info="taxesMYInfo"
          :taxes-m-x-info="taxesMXInfo"
        />

        <!-- 税费 一单一税的样式 -->
        <CheckoutSummaryTax
          v-for="tax in singleTaxes"
          :key="`tax_${tax.taxType}`"
          :tax="tax"
          :language="language"
          :is-show-ca-gst="isShowCaGST"
          :country-code="partOrderInfo.countryCode"
          :identification="`${priceDetailNameMap[`${'oneOrderOneTax'+tax.taxType}`]}`"
          class="price-detail__item"
        />
      </div>

      <slot></slot>

      <!-- 总价 total -->
      <div
        v-if="caculateInfo.grandTotalPrice"
        class="summary-order-total"
      >
        <span class="summary-order-total__left-wrapper">
          <template v-if="placeorderStatusmoveByAbt && !cccTaxIncludedTips">
            {{ language.SHEIN_KEY_PC_29865 }}:
          </template>
          <template v-else-if="placeorderStatusmoveByAbt && cccTaxIncludedTips">
            {{ language.SHEIN_KEY_PC_14752 }} &nbsp;{{ cccTaxIncludedTips }}:
          </template>
          <template v-else>
            {{ language.SHEIN_KEY_PC_14752 }}<template v-if="cccTaxIncludedTips">&nbsp;{{ cccTaxIncludedTips }}</template>:
          </template>
        </span>
        <span class="summary-order-total__right-wrapper">
          <span :class="{'summary-order-total__right-discount': isDiscountTotalColor }">
            {{ grandTotalPrice }}
          </span>
          <!-- 税费 S -->
          <span
            v-if="checkShowTotalFee"
            class="summary-order-total__right-tax"
          >
            + {{ govTaxPrice }}
            <s-popover
              :hide-close-icon="true"
              placemen="bottom"
              trigger="hover"
              :content="language.SHEIN_KEY_PC_19814"
            >
              <template #reference>
                <sui_icon_doubt_14px_1 
                  size="15px" 
                  color="#767676"
                />
              </template>
            </s-popover>
          </span>
          <!-- 税费 E -->
          <span
            v-if="caculateInfo.subGrandTotalPrice"
            class="sub-price"
          >( {{ caculateInfo.subGrandTotalPrice.amountWithSymbol }} )</span>
        </span>
        <!-- cod后端转换货币提示 -->
        <div
          v-if="codCurrencyTip"
          class="cod-change-currency-tip"
        >
          <s-popover
            trigger="hover"
            placemen="bottom"
            class="not-fsp-element"
          >
            <template #reference>
              <sui_icon_closed_14px_1 
                size="12px" 
                color="#222222"
                :aria-label="codCurrencyTip"
                @click="updateCheckoutState({ key: 'codCurrencyTip', val: '' })"
              />
            </template>
            <em class="tip-bottom-right with-arrow">{{ codCurrencyTip }}</em>
          </s-popover>
        </div>
      </div>
      <div
        v-if="checkShowTotalFee"
        class="gov-tax-desc"
      >
        <span
          v-html="template(govTaxPrice, language.SHEIN_KEY_PC_19813)"
        ></span>
      </div>
      <!-- 公司税费 1:GST 2:ST 3:VAT -->
      <OrderSummaryTaxes
        v-if="!isBrOrderInSheinWithMultiTax && !isClOrderInSheinWithMultiTax && !isMxOrderInShein && newTaxList && newTaxList.length"
        :taxes="newTaxList"
        :language="language"
        :is-multi-tax="false"
        :is-show-ca-gst="isShowCaGST"
        :part-order-info="partOrderInfo"
        :taxes-m-y-info="taxesMYInfo"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import OrderSummaryTaxes from '../../../vue_tpls/OrderSummaryTaxes.vue'
import OrderSummaryMultiTax from '../../../vue_tpls/OrderSummaryMultiTax.vue'
import CheckoutSummaryTax from '../CheckoutSummaryTax.vue'
import primeVipIcon from 'public/src/pages/common/prime/primeVipIcon.vue'
import { taxTypeConfig } from 'public/src/pages/common/helpers.js'
import ItemDetails from '../CheckoutSummaryPriceItemDetails'
import PromotionInfo from './promotion_info/PromotionInfo'
import CouponDiscountInfo from './CouponDiscountInfo'

import priceMixin from '../../../mixins/priceMixin'
import { mallMerge, handleShipFeeGoods } from '../../../config/tools'
import { sui_icon_doubt_14px_1, sui_icon_club_logo_fill_15px, sui_icon_closed_14px_1, sui_icon_more_up_12px_1, sui_icon_more_down_12px_1 } from '@shein-aidc/icon-vue3'

const PriceItemType = {
  subTotal: 'subTotal',
  shippingFee: 'shippingFee'
}

// 一单一税费 eg：oneOrderOneTax1: 'one order one tax 1', 税费 一单一税费（类型是1） one order one tax 1
const oneOrderOneTax = {}
Object.keys(taxTypeConfig).forEach(i => oneOrderOneTax[`oneOrderOneTax${i}`] = `one order one tax ${i}`)

const priceDetailNameMap = {
  ...oneOrderOneTax,
  retailPrice: 'retail price', // 原价 retail price 
  promotion: 'promotion', // 促销活动优惠总金额 promotions
  subtotal: 'subtotal', // 总价 subtotal
  shippingFee: 'shipping fee', // 运费 shipping fee
  QSFee: 'QS fee', // qs运费
  SHEINSaverFee: 'SHEIN Saver Fee', // 超省卡开通费用 SHEIN Saver Fee
  SHEINCLUBFee: 'SHEIN CLUB FEE', // 付费会员 SHEIN CLUB FEE
  retailDeliveryFee: 'retail delivery fee', // 美国CO消费税 零售送货费 retail delivery fee
  insurance: 'insurance', // 运费险退货险保价险
  handingFee: 'handing fee', // 处置费 Handling fee
  giftCardDeduction: 'gift card deduction', // 礼品卡扣除 gift card deduction
  CODFee: 'COD fee', // COD COD fee
  discount: 'discount', // 优惠金额券 优惠免邮券 抵扣金额 Discount
  SHEINPoints: 'SHEIN Points', // 积分 SHEIN Points
  productDiscount: 'product discount', // 付费会员 商品优惠价格
  paymentMethodDiscount: 'payment method discount', // 在线优惠 支付方式优惠 payment method discount
  walletCredit: 'Wallet Credit', // 钱包 Wallet Credit
  addTax: 'add tax', // 增值税 add tax【暂时没有】
  referralDiscount: 'Referral Discount', // 推荐折扣 Referral Discount
  oneOrderMultiTax: 'one order multi tax', // 税费 一单多税费 one order multi tax
}

export default {
  components: {
    primeVipIcon,
    OrderSummaryTaxes,
    OrderSummaryMultiTax,
    CheckoutSummaryTax,
    ItemDetails,
    PromotionInfo,
    CouponDiscountInfo,
    sui_icon_doubt_14px_1,
    sui_icon_club_logo_fill_15px,
    sui_icon_closed_14px_1,
    sui_icon_more_up_12px_1, 
    sui_icon_more_down_12px_1
  },
  mixins: [priceMixin],
  props: {
    isDiscountTotalColor: {
      type: Boolean,
      default: false,
    },
    prePayUnPayParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      PriceItemType,
      priceDetailNameMap,
      showSubTotalDetails: false,
      showShippingFeeDetails: false,
      actualCodePrice: '',
    }
  },
  computed: {
    ...mapState([
      'codCurrencyTip',
      'checkout',
      'defaultShipping',
      'language',
      'coupon',
      'qsFreightTips',
      'isPrimeMember',
      'rightConfigList',
      'usedPrimeCode',
      'caculateData',
      'mallCartsList',
      'isShowTokenBinDiscountBlock',
      'tokenBinDiscountDesc',
      'status',
      'locals',
      'tokenBinDiscountType'
    ]),
    ...mapGetters([
      'isFreePrice',
      'placeorderStatusmoveByAbt',
      'isSuggested',
      'isSuggestedInDe'
    ]),
    isUsMNState() {
      // !MN 的情况用默认文案兜底
      return this.checkout?.default_address?.stateId == '64712'
    },
    RDFTitle() {
      return this.isUsMNState ? this.language.SHEIN_KEY_PC_31534 : this.language.SHEIN_KEY_PC_22171
    },
    RDFPopuText() {
      return this.isUsMNState ? this.language.SHEIN_KEY_PC_31533 : this.language.SHEIN_KEY_PC_22206
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    IS_RW() {
      return this.locals.IS_RW
    },
    isRouterPay() {
      return this.status?.cardPay === 'routepay-card'
    },
    isInstallmentPay() {
      return this.status?.cardPay === 'routepay-cardinstallment'
    },
    showNotAddressRetailPrice() {
      return !this.isSuggested && this.caculateInfo?.originPrice?.amountWithSymbol != this.caculateInfo?.totalPrice?.amountWithSymbol
    },
    showPromotionInfo() {
      if(!!this.isSuggested) {
        return +this.caculateInfo?.every_body_promotion_discount?.amount > 0 || +this.caculateInfo?.exclusive_promotion_discount?.amount > 0
      } else {
        return +this.caculateInfo?.total_promotion_discount?.amount > 0 && +this.caculateInfo?.newSubTotal?.amount != +this.caculateInfo?.originPrice?.amount
      }
    },
    showLowestPrice() {
      return this.isSuggested && +this.caculateInfo?.crossed_price?.amount > 0 && +this.caculateInfo?.every_body_promotion_discount?.amount > 0
    },
    partOrderInfo() {
      return {
        countryCode: this.orderCountryCode,
        person_all_tax: this.caculateInfo?.personal_tax,
        total_vat_tax: this.caculateInfo?.total_vat_tax || {}
      }
    },
    taxesMYInfo(){
      return {
        companyTax: this.caculateInfo?.company_tax,
        personTax: this.caculateInfo?.total_tax,
        totalTax: this.caculateInfo?.personal_tax,
      }
    },
    taxesMXInfo() {
      return {
        personTax: this.caculateInfo?.total_tax,
        companySubsidy: this.caculateInfo?.tax?.find(item => item.taxPayType == 1 && item.taxType == 1 && +item.taxPrice.amount > 0)
      }
    },
    isBrOrderInSheinWithMultiTax() {
      return !this.IS_RW && this.isMultiTaxCountry && ['BR'].includes(this.orderCountryCode)
    },
    isMxOrderInShein() {
      return !this.IS_RW && ['MX'].includes(this.orderCountryCode)
    },
    // 非 br 一单多税的税费展示，不同国家的判断逻辑会有所不同，因此需要区分一下
    showNotBrOrderWithMultiTax() {
       // 非 br 一单多税
       const notBrTaxesVisible = !this.isBrOrderInSheinWithMultiTax && this.personalTaxes?.length
      // cl 税费明细
      const clTaxesVisible = this.isClOrderInSheinWithMultiTax && this.personalTaxes?.length
      // mx 税费明细
      const mxTaxesVisible = this.isMxOrderInShein && +this.caculateInfo?.total_tax?.amount > 0
      return notBrTaxesVisible || clTaxesVisible || mxTaxesVisible
    },
    ICMSTax() {
      const { tax } = this.caculateInfo
      // 公司支付，且税费类型为商品增值税ICMS，且不为0
      const ICMSTax = tax.filter(item => item.taxPayType == 1 && item.taxType == 3 && +item.taxPrice.amount > 0)
      return ICMSTax || []
    },
    FVATTax() {
      const { tax } = this.caculateInfo
      // 公司支付，且税费类型为巴西新增运费增值税FVAT，且不为0
      const FVATTax = tax.filter(item => item.taxPayType == 1 && item.taxType == 8 && +item.taxPrice.amount > 0)
      return FVATTax || []
    },
    hasSubTotalDetails() {
      const { newSubTotalWithoutVat } = this.caculateInfo

      // br订单，公司支付，且有商品增值税ICMS，才有取值，但没有过滤税费为0的情况
      const taxFulfilled = +newSubTotalWithoutVat?.amount > 0
      // BR订单：shein && 一单多税 && BR订单 && 有税费 && 税费不为0
      let brHasSubTotalDetails = this.isBrOrderInSheinWithMultiTax && taxFulfilled && this.ICMSTax.length > 0

      return brHasSubTotalDetails
    },
    hasShippingFeeDetails() {
      const { actual_shipping_price_without_vat } = this.caculateInfo

      // br订单，公司支付，且有巴西新增运费增值税FVAT，才有取值，但没有过滤税费为0的情况
      const taxFulfilled = +actual_shipping_price_without_vat?.amount > 0
      // shein && 一单多税 && BR订单 && 有税费 && 税费不为0
      return this.isBrOrderInSheinWithMultiTax && taxFulfilled && this.FVATTax.length > 0
    },
    shippingFeeData() {
      return this.handleShippingFeeData()
    },
    showShippingFeeTips() {
      if(this.IS_RW) return false

      const { mall_list = [] } = this.shippingFeeData
      let rule_id_list = []
      mall_list.forEach(mall => { 
        mall.rule_list?.forEach(item => {
          rule_id_list.push(+item.freight_free_rule_id)
        })
        
      })
      rule_id_list = [...new Set(rule_id_list)]
      return rule_id_list.length > 1
    },
    isShowShipOrigin() {
      const showSaveAmount = this.checkout.checkoutBFFAbtInfo?.Shownewsaveamount?.param?.show_new_save_amount == 'on' ? true : false
      if(showSaveAmount) {
        const { actual_shipping_price, origin_shipping_price } = this.caculateInfo
        return (origin_shipping_price?.amountWithSymbol && actual_shipping_price?.amountWithSymbol) && (+origin_shipping_price?.amount > +actual_shipping_price?.amount)
      } else {
        return false
      }
    },
    primeDeduceTips() {
      let tips = this.language.SHEIN_KEY_PC_25403 || ''
      if(!!this.usedPrimeCode) { // 随单购场景
        if(!!this.rightConfigList.length) { // 存在当前勾选产品包权益列表
          const discountRight = this.rightConfigList.filter(item => item.right_type_code == 'EXCLUSIVE_DISCOUNTS' && item.is_has_quota == 1) || []
          const right_quota_info = discountRight[0]?.right_quota_info || {}
          const { grant_threshold, grant_threshold_with_symbol } = right_quota_info

          if(+grant_threshold > 0 && !!grant_threshold_with_symbol) tips = this.template(grant_threshold_with_symbol, this.language.SHEIN_KEY_PC_25402)
        }
      } else if(this.isPrimeMember) { // 付费会员场景（注：试用会员+随单购 逻辑在随单购场景取值）
        const primeInfo = this.caculateInfo?.promotion_grant_amount_info || {}
        const totalAmount = primeInfo?.cycle_grant_amount?.amountWithSymbol || ''
        const totalAmountNum = primeInfo?.cycle_grant_amount?.amount || '0'

        if(+totalAmountNum > 0 && !!totalAmount) tips = this.template(totalAmount, this.language.SHEIN_KEY_PC_25402)
      }

      return tips
    },
    primeDiscountTotal() {
      const primeInfo = this.caculateInfo?.promotion_grant_amount_info || {}
      const totalAmount = primeInfo?.cycle_grant_amount?.amountWithSymbol || ''
      const totalAmountNum = primeInfo?.cycle_grant_amount?.amount || '0'
      const usedAmount = primeInfo?.cycle_used_grant_amount?.amountWithSymbol || ''
      if (!totalAmount || +totalAmountNum == 0) return ''
      return this.template(usedAmount, totalAmount, this.language.SHEIN_KEY_PC_24014) 
    },
    caculateInfo() {
      return this.checkout?.mall_caculate_info || {}
    },
    isFreeInfo(){
      const { insurancePrice } = this.checkout?.mall_caculate_info || {}
      const isFREE = [0, 1].includes(+this.mallInsuranceCheck?.[0]?.insurance_type) && insurancePrice?.amount == 0 ? 'FREE' : ''
      return {
        isFREE
      }
    },
    newTaxList() {
      let newTax = []
      const taxList = this.caculateInfo.tax
      if (taxList?.length) {
        taxList.forEach((tax) => {
          if (tax.taxPrice && tax.taxPrice.amount > 0 && tax.taxPayType == 1) {
            newTax.push(tax)
          }
        })
      }
      return newTax
    },
    personalTaxes() {
      let newTax = []
      const taxList = this.caculateInfo.tax
      if (taxList?.length) {
        taxList.forEach((tax) => {
          if (tax.taxPrice && tax.taxPrice.amount > 0 && tax.taxPayType == 2) {
            newTax.push(tax)
          }
          if(this.isClOrderInSheinWithMultiTax && tax.taxPrice.amount > 0 && tax.taxPayType == 1) {
            newTax.push(tax)
          }
        })
      }
      return newTax
    },
    // 一单一税的数据
    singleTaxes() {
      if (!this.personalTaxes.length || this.isMultiTaxCountry) {
        return []
      }
      return this.personalTaxes?.filter(f => !(f.taxPayType == 1 && f.taxType == 2))
    },
    codPrice() {
      if (this.caculateData.payment_code_unique !== 'cod') {
        this.handleChangeCodePrice('')
        return ''
      }
      const { show_type = '', codPrice: {  amountWithSymbol: currentPrice, amount: currentPriceAmount, } = {} } = this.caculateInfo
      const cur = Number(currentPriceAmount) > 0 ? currentPrice || '' : `<span style="color: #198055">${this.language.SHEIN_KEY_PC_19647}</span>`
      const actualNum = Number(currentPriceAmount) > 0 ?  Number(currentPriceAmount) : 0

      if (show_type === 2) {
        this.handleChangeCodePrice(actualNum)
        return cur
      }
      if (show_type === 1) {
        const { origin_service_fee: { amountWithSymbol: originPrice, amount: originPriceAmount, } = {} } = this.caculateInfo
        const origin = Number(originPriceAmount) > 0 ? originPrice || '' : `<span style="color: #198055">${this.language.SHEIN_KEY_PC_19647}</span>`
        this.handleChangeCodePrice(actualNum)
        return `<del style="color: #767676;">${origin}</del> ${cur}`
      }
      return ''
    },
    checkShowTotalFee() {
      return (
        this.isShowTotalFee &&
        this.caculateInfo?.pay_method_gov_tax_info?.pay_method_gov_tax_rate > 0 &&
        Number(this.caculateInfo?.pay_method_gov_tax_info?.pay_method_gov_tax_amount?.amount || '0') > 0
      )
    },
    govTaxPrice() {
      return (
        this.caculateInfo?.pay_method_gov_tax_info?.pay_method_gov_tax_amount
          ?.amountWithSymbol || 0
      )
    },
    getHandlingDescText() {
      let { mall_list = [] } = this.caculateInfo || {}
      let newList = mallMerge(mall_list, this.defaultShipping)
      if (newList?.length) {
        let handlingDescList = newList?.filter((mall) => !!mall.handling_desc)
        let handlingDescArr = handlingDescList?.map(
          (handlingDescObj) =>
            `<div>${handlingDescObj?.shipping_method?.title}: ${handlingDescObj.handling_desc}</div>`
        )
        return handlingDescArr.join('')
      }
      return ''
    },
    isUsePrimeCoupon() {
      const filterCoupon = this.coupon?.applyCouponsSuccess?.filter(item => item.applyFor == 9 && item.typeId == 53) || []
      return !!filterCoupon.length
    },
    isClOrderInSheinWithMultiTax() {
      return !this.IS_RW && ['CL'].includes(this.orderCountryCode)
    },
    isMultiTaxCountry () {
      if(this.isClOrderInSheinWithMultiTax) return true
      // mx 税费明细样式 与 一单多税样式 一致
      if (this.isMxOrderInShein) return true
      return this.locals?.MULTIPLE_TAXES_SPLIT_DISPLAY?.includes(this.orderCountryCode)
    },
    // 判断是否展示ca gst 提示文案
    isShowCaGST() {
      const { countryId } = this.checkout?.default_address || {}
      return countryId == '38' || this.SiteUID == 'ca'
    },
    orderCountryCode () {
      return this.checkout?.default_address?.value || ''
    },
    cccTaxIncludedTips () {
      return this.checkout?.results?.cccTaxIncludedInfo?.taxIncludesTips || ''
    },
    isShowTotalFee() {
      return this.checkout?.checkoutBFFAbtInfo?.TotalFee?.param == 'ShowTotalFee' ? true : false
    },
    installmentFee() {
      return this.prePayUnPayParams?.installmentsInfo?.installment_fee
    },

    installments() {
      return this.prePayUnPayParams?.installments
    },

    isShowCardBinDiscountDescInPriceDetail() {
      return this.prePayUnPayParams?.isShowCardBinDiscountDescInPriceDetail
    },

    cardBinDiscountDesc() {
      return this.prePayUnPayParams?.cardBinDiscountDesc
    },

    resultTokenBinDiscountDesc() {
      return this.isShowCardBinDiscountDescInPriceDetail ? this.cardBinDiscountDesc : this.tokenBinDiscountDesc
    },

    grandTotalPrice() {
      const totalPrice = this.caculateInfo?.grandTotalPrice?.amountWithSymbol

      if (this.isInstallmentPay && Object.keys(this.prePayUnPayParams?.installmentsInfo || {}).length > 0) {
        return this.prePayUnPayParams?.installmentsInfo?.total_amount
      }
      return totalPrice
    },
  },
  watch: {
    caculateInfo: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.handlePriceDetailSort()
        }
      },
      deep: true
    }
  },
  mounted() {
    this.handlePriceDetailSort()
  },
  methods: {
    ...mapMutations(['updateCheckoutState', 'assignState']),
    template,
    cartNumTotal(carts) {
      if( !carts.length ) return ''
      let total = carts.reduce((total, item)=> {
        total = total + Number(item.quantity)
        return total
      }, 0)
      return total
    },
    handleChangeCodePrice(val) {
      this.actualCodePrice = val
    },
    handlePriceDetailSort() {
      this.$nextTick(() => {
        const priceDetailSortEl = this.$refs.priceDetailSortRef
        const childrenList = priceDetailSortEl?.children || []

        if (!Array.from(childrenList).length || !priceDetailSortEl) {
          return
        }

        // 一单一税的数据
        const singleTaxesData = this.singleTaxes?.map(item => ({ identification: priceDetailNameMap[`${'oneOrderOneTax' + item?.taxType}`], value: +item?.taxPrice?.amount }))

        // 一单多税的数据
        const singleOrderMultiTaxesData = this.isMultiTaxCountry && ['BR', 'MY']?.includes(this.partOrderInfo?.countryCode) ? [{ identification: priceDetailNameMap.oneOrderMultiTax, value: +this.taxesMYInfo?.totalTax?.amount }] : []

        const priceDetailData = [
          { identification: priceDetailNameMap.shippingFee, value: this.isFreePrice ? 0 : +this.caculateInfo?.actual_shipping_price?.amount, isShow: true },
          { identification: priceDetailNameMap.QSFee, value: +this.caculateInfo.qs_shipping_price?.amount > 0 ? +this.caculateInfo.qs_shipping_price.amount : 0 },
          { identification: priceDetailNameMap.SHEINSaverFee, value: +this.caculateInfo.save_card_price?.amount > 0 ? +this.caculateInfo.save_card_price.amount : 0 },
          { identification: priceDetailNameMap.SHEINCLUBFee, value: +this.caculateInfo.prime_price?.amount > 0 ? +this.caculateInfo.prime_price.amount : 0 },
          { identification: priceDetailNameMap.insurance, value: this.showInsurancePrice.length ? (this.isFreeInfo?.isFREE == 'FREE' ? 0 : +this.caculateInfo.insurancePrice?.amount ) : 0, isShow: this.showInsurancePrice.length },
          { identification: priceDetailNameMap.retailDeliveryFee, value: this.caculateInfo.retail_delivery_fee && parseFloat(this.caculateInfo.retail_delivery_fee?.amount) > 0 ? this.caculateInfo.retail_delivery_fee?.amount : 0 },
          { identification: priceDetailNameMap.handingFee, value: +this.caculateInfo.handling_fee?.amount > 0 ? +this.caculateInfo.handling_fee.amount : 0 },
          { identification: priceDetailNameMap.CODFee, value: +this.actualCodePrice },
          { identification: priceDetailNameMap.addTax, value: this.caculateInfo?.taxEnabled == 1 ? +this.caculateInfo?.warPrice?.amount : 0 },
          { identification: priceDetailNameMap.discount, value: this.showDiscount ? +this.caculateInfo?.couponPrice?.amount : 0, isDiscount: true },
          { identification: priceDetailNameMap.SHEINPoints, value: +this.caculateInfo.pointPrice?.amount > 0 ? +this.caculateInfo.pointPrice.amount : 0, isDiscount: true },
          { identification: priceDetailNameMap.productDiscount, value: +this.caculateInfo.prime_deduce_price?.amount > 0 ? +this.caculateInfo.prime_deduce_price.amount : 0, isDiscount: true },
          { identification: priceDetailNameMap.paymentMethodDiscount, isDiscount: true,
            value: this.caculateInfo?.onlinePayDiscountInfo?.onlinePayDiscountInfo?.isEnjoyedDiscount == 1 && this.caculateInfo?.onlinePayDiscountInfo?.onlinePayDiscountInfo?.discountType != 2 ? +this.caculateInfo?.onlinePayDiscountInfo?.onlinePayDiscountInfo?.discountPrice?.amount : 0 
          },
          { identification: priceDetailNameMap.walletCredit, value: +this.caculateInfo.walletPrice?.amount > 0 ? +this.caculateInfo.walletPrice.amount : 0, isDiscount: true },
          { identification: priceDetailNameMap.giftCardDeduction, isDiscount: true,
            value: this.caculateInfo?.giftcard?.giftCardUsedPrice && this.caculateInfo?.giftcard?.giftCardUsedCurrencyAmount > 0 ? +this.caculateInfo?.giftcard?.giftCardUsedPrice?.amount : 0 
          },
          { identification: priceDetailNameMap.referralDiscount, isDiscount: true,
            value: this.caculateInfo?.extraPromotion?.marketFission?.isValidated == 1 && this.caculateInfo?.extraPromotion?.marketFission?.amount?.amount > 0 ? +this.caculateInfo?.extraPromotion?.marketFission?.amount?.amount : 0 
          },
        ].concat(singleTaxesData).concat(singleOrderMultiTaxesData)

        const zeroNeedShow = [priceDetailNameMap.shippingFee, priceDetailNameMap.insurance]
        const freeData = [] // 数值即是为0，但是需要展示，但是：priceDetailNameMap.insurance需要前置条件
        const collectFreeData = [] // 数值大于0的价格项
        const discountData = [] // 数值为优惠的价格项目，由于接口返回的字段都是没有带符号的值，因此需要加上isDiscount共同判断

        priceDetailData?.forEach(item => {
          const { identification, value, isShow, isDiscount } = item
          if (zeroNeedShow.includes(identification) && isShow && value == 0) {
            freeData.push(item)
          } else if (value > 0 && (!isDiscount || isShow)) {
            collectFreeData.push(item)
          } else if (value > 0 && isDiscount) {
            discountData.push(item)
          }
        })

        // 对于价格从高到低排序
        if (collectFreeData.length > 1) {
          collectFreeData.sort((a, b) => b.value - a.value)
        }
        if (discountData.length > 1) {
          discountData.sort((a, b) => b.value - a.value)
        }

        const newPriceDetailData = freeData.concat(collectFreeData).concat(discountData)
        const newDOMArchitecture = document.createDocumentFragment()

        // 按照排序后的的newPriceDetailData数组，对DOM进行重新排序
        newPriceDetailData?.forEach(item => {
          const { identification } = item
          const liElements = Array.from(childrenList).filter(i => i?.getAttribute('identification') == identification)
          if (liElements?.length === 1) {
            newDOMArchitecture.appendChild(liElements[0])
          }
        })
        priceDetailSortEl?.appendChild(newDOMArchitecture)
      })
    },
    getItemDetails(itemType) {
      const { actual_shipping_price_without_vat } = this.caculateInfo
      switch (itemType) {
        case PriceItemType.subTotal: 
          return [
            { 
              title: this.language.SHEIN_KEY_PC_27347, 
              price: this.caculateInfo.newSubTotal.amountWithSymbol,
            },
            {
              title: this.language.SHEIN_KEY_PC_26613,
              price: this.ICMSTax[0]?.taxPrice?.amountWithSymbol,
              tips: this.language.SHEIN_KEY_PC_27376,
            },
            {
              title: this.language.SHEIN_KEY_PC_27506,
              price: '- ' + this.ICMSTax[0]?.taxPrice?.amountWithSymbol,
              tips: this.language.SHEIN_KEY_PC_27507,
            }
          ]
        case PriceItemType.shippingFee:
          return [
            { 
              title: this.language.SHEIN_KEY_PC_27350, 
              price: actual_shipping_price_without_vat?.amountWithSymbol 
            },
            {
              title: this.language.SHEIN_KEY_PC_27348,
              price: this.FVATTax[0]?.taxPrice?.amountWithSymbol,
              tips: this.language.SHEIN_KEY_PC_27349
            }
          ]
      }
    },
    async mergeShipFeeSpuInfo() {
      const { mall_list = [] } = this.shippingFeeData
      let requestArr = []
      mall_list.forEach(async mall => {
        if(!!mall.rule_list?.length) {
          requestArr.push(handleShipFeeGoods(mall.rule_list))
        } else {
          requestArr.push(Promise.resolve({ code: -1, info: {} }))
        }
      })

      await Promise.all(requestArr)
    },
    async clickShippingFee() {
      await this.mergeShipFeeSpuInfo()
      this.assignState({
        showShippingFeeDrawer: true,
        shippingFeeRuleInfo: this.shippingFeeData
      })
    },
    handleShippingFeeData() {
      if(!this.mallCartsList.length) return {}

      let arr = []
      const { mall_list = [] } = this.checkout?.mall_caculate_info || {}
      this.mallCartsList.forEach(mall => {
        const mall_code = mall?.[0]?.mall_code || ''
        const cartsMallInfo = this.checkout.results?.carts?.mall_list?.find(item => item.mall_code == mall_code) || {}

        const filterCalcu = mall_list.filter(item => item.mall_code == mall_code)
        const shipping_price_all = filterCalcu[0]?.shipping_price_all || []

        const filterShip = this.defaultShipping?.filter(item => item.mall_code == mall_code) || []
        const shipping_method = filterShip?.[0]?.shipping_method || {}

        const filterRule = shipping_price_all.filter(item => item.transport_type == shipping_method.transport_type) || []
        const ruleList = filterRule[0]?.shipping_free_rule_info_list || []

        arr.push({
          mall_code: cartsMallInfo.mall_code || '1',
          mall_name: cartsMallInfo.mall_name || '',
          rule_list: ruleList,
        })
      })

      return { 
        mall_list: arr, 
        total_price: this.caculateInfo.actual_shipping_price,
        save_price: this.caculateInfo.shipping_price_diff
      }
    },
  },
}
</script>

<style lang="less">
.checkout-order-summary__body {
  .summary-item{
    &__subtotal {
      cursor: pointer;
    }
		&.gov-tips {
			.txt-r();
			margin-top: -5px;
			color: #767676;
			b {
				color: #222;
			}
		}
	}
}

.price-detail {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    font-size: 13px;
    color: var(---sui_color_gray_dark1, #000);
    font-size: 13px;
    font-weight: 400;
  }
  &__item-total {
    position: relative;
    margin-bottom: 0;
    line-height: 24px;
  }
  &__item-left {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
  &__item-left-icon {
    color: #999;
  }
  &__item-right {
    flex-shrink: 0;
  }
  &__item-right-total {
    font-size: 20px;
    font-weight: 700;
  }
  &__item-right-icon {
    margin-left: 4px;
  }
  &__item-right-text {
    margin-left: 2px;
  }
  &__item-right-discount {
    color: var(--Rectangle-708, #FA6338);
    margin-left: 2px;
  }
  &__tips {
    font-size: 12px;
    color: var(---sui_color_gray_dark3, #767676);
    margin: -4px 0 8px;
  }
  .price-detail-sort {
    padding-top: 4px;
  }
  .free-price {
    color: @sui_color_safety;
    margin-left: 4px;
  }
  .product-discount-info {
    margin-top: -4px;
    margin-bottom: 8px;
    color: @sui_color_gray_dark3;
  }
  .price-prime {
    margin-left: 4px;
    color: @sui_color_club_rosegold_dark2;
  }
  .origin-price {
    color: @sui_color_gray_light1;
    .margin-l(4px);
  }

  .free-prime-color {
    color: #C96E3F;
  }
  .subtotal-bottom-border {
    border-bottom: 1px solid #e5e5e5;
  }
}
.summary-order-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px;
  &__left-wrapper {
    color: var(---sui_color_gray_dark1, #000);
    font-size: 14px;
    font-weight: 700;
  }
  &__right-wrapper {
    color: var(---sui_color_gray_dark1, #000);
    text-align: right;
    font-size: 18px;
    font-weight: 900;
  }
  &__right-discount {
    color: @sui_color_discount;
  }
  &__right-tax {
    color: var(---sui_color_gray_dark1, #000);
    text-align: right;
    font-size: 16px;
    font-weight: 900;
  }
  &__right-tax-icon {
    font-weight: 400;
					font-size: 16px;
  }
  &__right-sub-price {
    display: block;
    font-size: 10px;
    line-height: 11px;
    font-weight: normal;
    color: #767676;
    & when (@IS_RW) {
      color: #bbbbbb;
    }
  }
}
.gov-tax-desc {
  margin-top: 8px;
  color: var(---sui_color_gray_dark2, #666);
  text-align: right;
  font-size: 12px;
  font-weight: 400;
}
.sui_icon_doubt_14px_1.summary-gray {
  color: @sui_color_gray_light1;
  font-size: 12px;
  cursor: pointer;
}
.summary-popover {
  padding: 0 2px;
}
.summary-coupon {
  overflow: hidden;
}
.summary-coupon-txt {
  .text-overflow();
  .txt-r();
  margin-top: 5px;
  span {
    color: @sui_color_gray_light1;
  }
}

</style>
