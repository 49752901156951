<template>
  <div 
    class="check-btn j-place-order"
    :class="[
      isShowFreeShippingTip ? 'checkout-btn-wrapper' : '',
    ]"
  >
    <!-- paypal ga 按钮 -这里使用v-show，避免使用v-if而导致sdk找不到dom -->
    <div
      v-show="paypalBtnShow"
      id="paypal-vault-button"
    ></div>
    <div
      v-show="paypalBnplBtnShow"
      id="paypal-bnpl-button"
    ></div>
    <!-- vemo按钮 -->
    <s-button
      v-show="!paypalBtnShow && !paypalBnplBtnShow && paypalVenmoBtnShow && !applePayBtnShow"
      class="venmo-btn"
      :type="['primary', 'H54PX']"
      @click="createOrder"
    >
      <img
        v-show="!status.checkout"
        :src="IMG_LINK['blue-venmo-button']"
        class="venmo-btn-img"
      />
      <div
        :class="[
          'la-ball-pulse',
          'la-ball-pulse-white',
          { 'she-hide': !status.checkout },
        ]"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </s-button>
    <ApplePayBtn
      v-if="applePayBtnShow"
      :props-style="{ width: '100%', height: '54px' }"
      @handle-btn-click="createOrder"
    />
    <!-- 普通按钮 -->
    <s-button
      v-show="!paypalBtnShow && !paypalBnplBtnShow && !paypalVenmoBtnShow && !applePayBtnShow"
      :aria-describedby="status.error.nopayment ? 'nopaymentTip' : ''"
      :type="['primary', 'H54PX']"
      :class="{ 'checkout-de': ['de', 'ch', 'eurde'].indexOf(lang) > -1 }"
      :disabled="disableCreate"
      @click="createOrder"
    >
      <div
        v-show="!status.checkout" 
      >
        <!-- 左图右文布局 -->
        <div
          v-if="nomalBtnLayoutType == 2"
          class="btn-first-layout"
          :class="[isShowOrderBtnBenefit ? 'benefit-btn-layout' : '']"
        >
          <img
            v-if="normalBtnData.logo"
            class="btn-first-layout__logo"
            :src="normalBtnData.logo"
          />
          <p
            class="btn-first-layout__text"
            v-html="normalBtnData.placeOrderText"
          ></p>
        </div>
        <!-- 左文右图布局 -->
        <div
          v-else-if="nomalBtnLayoutType == 3"
          class="btn-second-layout"
          :class="[isShowOrderBtnBenefit ? 'benefit-btn-layout' : '']"
        >
          <p
            class="btn-second-layout__text"
            v-html="normalBtnData.placeOrderText"
          ></p>
          <img
            v-if="normalBtnData.logo"
            class="btn-second-layout__logo"
            :src="normalBtnData.logo"
          />
        </div>
        <!-- 默认 -->
        <div
          v-else
          :class="[isShowOrderBtnBenefit ? 'benefit-btn-layout' : '']"
        >
          {{ placeOrderTxt }}
        </div>

        <!-- 按钮的利益点信息 -->
        <CheckoutBtnBenefit v-if="isShowOrderBtnBenefit" />
      </div>
      <div
        :class="[
          'la-ball-pulse',
          'la-ball-pulse-white',
          { 'she-hide': !status.checkout },
        ]"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </s-button>
    <!-- 免邮提示 -->
    <ClientOnly>
      <div
        v-if="isShowFreeShippingTip"
        class="free-shipping-tip"
      >
        <labelsSwiper
          v-if="btnFreeQuickShipTips == 'quickshipFreeAll'"
          :config="labelSwiperConfig"
        />
        <template v-else>
          <Icon
            :name="quickShipFreeIcon"
            size="15px"
            color="#fff"
          />
          <span
            :class="['free-shipping-tip__text', isQuickShipBtnTips && 'italic-text']"
          >{{ btnFreeQuickShipTips }}</span>
        </template>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'

import { Icon } from '@shein-aidc/icon-vue3'
import labelsSwiper from 'public/src/pages/checkout/components/atom/labelsSwiper.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import ApplePayBtn from 'public/src/pages/components/ApplePayBtn.vue'
import applePayConfig from 'public/src/pages/common/apple_pay/index.js'

import CheckoutBtnBenefit from './CheckoutBtnBenefit.vue'

const normalBtnLayoutType = {
  TOP_TEXT_BOTTOM_IMAGE: 1, // 1是上文下图布局
  LEFT_IMAGE_RIGHT_TEXT: 2, // 2是左图右文布局
  LEFT_TEXT_RIGHT_IMAGE: 3, // 3是左文右图布局
  DEFAULT: -1 // 默认兜底
}

// 支付方式类型
const placeBtnPayType = {
  CARD_TYPE: 'CARDTYPE', // 卡支付类型	
  BNPL_TYPE: 'BNPLTYPE', // bnpl有利诱点的支付方式类型	
  OTHER_TYPE: 'OTHERTYPE', // 其余支付方式
}

// const applePayType = ['worldpay-applepay', 'nuvei-applepay', 'adyen-applepay', 'checkout-applepay', 'ingenico-applepay']

export default {
  name: 'SummaryCheckoutButton',
  components: { Icon, labelsSwiper, ClientOnly, ApplePayBtn, CheckoutBtnBenefit },
  props: {
    paypalBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    paypalBnplBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    installmentInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    paypalGaVault: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return{
      normalBtnData: {
        placeOrderText: '', // 按钮文案
        logo: '', // 支付方式的logo
        brandColor: '', // 品牌色（按钮用于设置背景色，用在BNPL的ABT实验为2的时候）
        subText: '', // 按钮副文案（用在BNPL的ABT实验为2的时候）
      },
      nomalBtnLayoutType: normalBtnLayoutType.DEFAULT, // 普通按钮的布局样式 1是上文下图布局、2是左图右文布局、3是左文右图布局
    }
  },
  computed: {
    ...mapState([
      'status',
      'checkout',
      'defaultShipping',
      'storeData',
      'language',
      'locals',
      'defaultSelectTokenPay',
      'handlerSelectBin',
      'installmentSelectToken',
      'installmentSelectBin'
    ]),
    ...mapGetters([
      'isFreePrice',
      'quickShipCheckoutStatus',
      'isSelectTokenPay',
      'isSelectInstallmentTokenPay',
      'orderBtnActualBenefitInfo',
      'orderBtnBenefitInfo',
      'defaultCheckoutButtonText'
    ]),
    lang() {
      return this.locals.lang
    },
    IMG_LINK() {
      return this.locals.IMG_LINK
    },
    IS_RW() {
      return this.locals.IS_RW
    },
    disableCreate() {
      return !this.checkout?.default_address?.addressId || (TRANSPORT_TYPE_SHOP.includes(this.defaultShipping?.[0]?.shipping_method?.transport_type) && (!this.storeData || this.storeData?.status != 1))
    },
    // 是否展示免邮的提示标签
    isShowFreeShippingTip() {
      return !this.IS_RW &&  this.isFreePrice || this.isQuickShipBtnTips
    },
    // 按钮利益点展示条件
    isShowOrderBtnBenefit() {
      if (!!this.orderBtnActualBenefitInfo.length) {
        return this.nomalBtnLayoutType == normalBtnLayoutType.DEFAULT ||
          (this.nomalBtnLayoutType == normalBtnLayoutType.LEFT_TEXT_RIGHT_IMAGE && !this.filterBNPLExclusiveData?.length) ||
          (this.nomalBtnLayoutType == normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT && this.isShowRouterCardPay)
      }
      return false
    },
    getPlaceOrderButtonPayStatusByAbt() {
      return !this.IS_RW && this.checkout?.checkoutBFFAbtInfo?.PlaceOrderButtonPay?.param?.PlaceOrderButtonPayStatus == 1
    },
    currentPayInPayListInfo() {
      return this.checkout?.results?.paymentMethods?.filter(i => this.status?.cardPay === i.code && i.enabled) || []
    },
    filterBNPLExclusiveData() {
      if (!this.status?.cardPay) {
        return []
      }
      return this.checkout?.BNPL_PAY_BTN?.filter(item => item.payCode === this.status?.cardPay)
    },
    // 获取分期的金额
    getFrontShowList() {
      return this.installmentInfo?.front_show_list || []
    },
    getInstallMentData() {
      if (!this.status?.cardPay) {
        return ''
      }
      const installmentData = this.getFrontShowList?.find(item => item.payment_code === this.status?.cardPay)
      const installmentAmount = installmentData?.place_order_period_price?.amountWithSymbol

      return installmentAmount || ''
    },
    // 卡路由 卡分期，需要监听这两个参数是否变化
    cardInfo() {
      const cardTokenList = this.status?.cardPay == 'routepay-card' && this.checkout?.results?.paymentMethods?.find(i => 'routepay-card' === i.code && i.enabled)?.card_token_list
      const cardInstallTokenList = this.status?.cardPay == 'routepay-cardinstallment' && this.checkout?.results?.paymentMethods?.find(i => 'routepay-cardinstallment' === i.code && i.enabled)?.card_token_list
      return [this.defaultSelectTokenPay?.id, this.installmentSelectBin, cardTokenList, cardInstallTokenList]
    },
    // 当前选中的卡支付信息
    selectCardInfo(){
      const currentCardInfo = this.checkout?.results?.paymentMethods?.find(i => 'routepay-card' === i.code && i.enabled)
      const info = currentCardInfo?.card_token_list?.find(f => f?.id == this.defaultSelectTokenPay?.id)
      return info
    },
    // 当前选中的卡分期信息
    selectCardInstallmentInfo(){
      const currentCardInfo = this.checkout?.results?.paymentMethods?.find(i => 'routepay-cardinstallment' === i.code)
      const info = currentCardInfo?.card_token_list?.find(f => f?.card_bin == this.installmentSelectBin)
      return info
    },
    // 卡支付/卡分期支付
    isShowRouterCardPay() {
      // const { webshowTokenFront } = this.checkout?.checkoutBFFAbtInfo
      const isShowTokenPaymentByAbt = true // 11.04已推全

      const routerCardVisible = isShowTokenPaymentByAbt && ['routepay-card'].includes(this.status?.cardPay) && !!Object.keys(this.selectCardInfo || {}).length && this.selectCardInfo
      const routerCardInstalment = this.isSelectInstallmentTokenPay && ['routepay-cardinstallment'].includes(this.status?.cardPay) && !!Object.keys(this.selectCardInstallmentInfo || {}).length && this.selectCardInstallmentInfo
      
      return this.getPlaceOrderButtonPayStatusByAbt && (routerCardVisible || routerCardInstalment)
    },
    isSpecialCardPay() {
      if (!this.status?.cardPay) {
        return false
      }
      const limitArr = ['PayPal-GApaypal', 'PayPal-Venmo', 'routepay-card', 'routepay-cardinstallment'].concat(this.applePayMethods)
      return !limitArr.includes(this.status?.cardPay)
    },
    isSatisfyBnplHasLure() {
      return this.getPlaceOrderButtonPayStatusByAbt && this.isSpecialCardPay && !!this.getInstallMentData
    },
    isSatisfyBnplHasNotLure() {
      return this.getPlaceOrderButtonPayStatusByAbt && this.isSpecialCardPay && !this.getInstallMentData
    },
    // 下单按钮文案
    placeOrderTxt() {
      let txt = this.defaultCheckoutButtonText

      if (this.getPlaceOrderButtonPayStatusByAbt && ['routepay-card', 'routepay-cardinstallment'].includes(this.status?.cardPay)) {
        return (txt || '').toUpperCase()
      }
      
      const { ContinueShow = {} } = this.checkout?.checkoutBFFAbtInfo || {}
      if (ContinueShow?.param?.Control_ContinueShow === 'on' && this.status?.cardPay !== 'routepay-card') {
        // abt 全推 placeOrderButtonUpgradeStatus写死 等于1 不受abt 控制
        txt = !this.IS_RW ? this.language?.SHEIN_KEY_PC_22336 : this.language?.SHEIN_KEY_PC_25317
      }
      return (txt || '').toUpperCase()
    },
    isQuickShipBtnTips(){
      const { isAllCartsQuickShip, PlaceOrderQuickshipWord } = this.quickShipCheckoutStatus
      return isAllCartsQuickShip && PlaceOrderQuickshipWord == 1
    },
    quickShipFreeIcon(){
      return this.isQuickShipBtnTips ? 'sui_icon_qucikship_flat_24px' : 'sui_icon_free_shipping_12px'
    },
    btnFreeQuickShipTips(){
      const { quickShipLabelText } = this.quickShipCheckoutStatus
      return this.isFreePrice  && this.isQuickShipBtnTips
        ? 'quickshipFreeAll'
        : this.isQuickShipBtnTips ? quickShipLabelText : this.language.SHEIN_KEY_PC_26630
    },
    labelSwiperConfig(){ 
      return [{
        text: this.quickShipCheckoutStatus.quickShipLabelText,
        icon: 'sui_icon_qucikship_flat_24px',
        quickship: true
      },
      {
        text: this.language.SHEIN_KEY_PC_26630,
        icon: 'sui_icon_free_shipping_12px',
      }
      ]
    },
    applePayBtnShow(){
      // const apolloInfo = this.checkout?.APPLE_PAY_CODE_LIST
      const apolloInfo = this.applePayMethods || []
      const applepayDropoffOptimizationAbt = this.checkout?.results?.applepayDropoffOptimizationAbt?.ApplepayButtonShow === 'Show'
      if(apolloInfo?.includes(this.status?.cardPay) && applepayDropoffOptimizationAbt){
        return true
      }
      return false
    },
    // paypal签约状态 0 是没有签约 1是签约
    paypalSignStatus() {
      return 'PayPal-GApaypal' == this.status?.cardPay && this.paypalGaVault?.radioType == 0
    },
    getOrderBtnBenefitData() {
      return Object.keys(this.orderBtnBenefitInfo)?.filter(i => this.orderBtnBenefitInfo[i]).join(',')
    },
    applePayMethods () {
      if (this.checkout?.APPLE_PAY_CODE_LIST?.length) return this.checkout.APPLE_PAY_CODE_LIST
      return applePayConfig?.applePayChannel || []
    },
    // 按钮的支付类型
    placeOrderPayType() {
      const payTypeStatus = this.checkout?.checkoutBFFAbtInfo?.PlaceOrderButtonPayStyle?.param?.PlaceOrderButtonPayStyleStatus
      return payTypeStatus.split(',').map(_ => _.trim().toUpperCase())
    }
  },
  watch: {
    'status.cardPay'(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.handleNormalBtnInfo()
      }
    },
    cardInfo(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.handleNormalBtnInfo()
      }
    },
    paypalSignStatus() {
      this.handleNormalBtnInfo()
    },
    getOrderBtnBenefitData(newVal, oldVal) {
      if (newVal != oldVal && newVal) {
        this.handleAnalyzeBenefit()
      }
    },
    getFrontShowList(newVal, oldVal) {
      if (newVal && newVal?.length > 0 && JSON.stringify(newVal) != JSON.stringify(oldVal)) {
        if (newVal.find(item => item.payment_code === this.status?.cardPay)) {
          this.handleNormalBtnInfo()
        }
      }
    }
  },
  mounted() {
    this.handleNormalBtnInfo()
    this.handleAnalyzeBenefit()
  },
  methods: {
    // 注意！！！此处不要有任何return事件，统一收口至最尾节点处理！！！下单按钮点击事件需要上报埋点及监控！！！
    createOrder() {
      this.$emit('createOrder')
    },
    handleBNPLPlaceText({ installmentColor,  wordColor }) {
      // 左图右文
      if (this.getInstallMentData && this.nomalBtnLayoutType == normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT) {
        if (this.isSatisfyBnplHasLure && !this.placeOrderPayType.includes(placeBtnPayType.BNPL_TYPE)) {
          return this.language.SHEIN_KEY_PC_22336 // continue
        }
        // pay {0} today
        return `<span style="color: ${wordColor}">${template(`<span style="color: ${installmentColor}">${this.getInstallMentData}</span>`, this.language.SHEIN_KEY_PC_30109)}</span>`
      }
      // 其他
      if (this.placeOrderPayType.includes(placeBtnPayType.OTHER_TYPE)) {
        // SHEIN_KEY_PC_32696 continue with SHEIN_KEY_PC_31198 pay with
        return this.checkout?.checkoutBFFAbtInfo?.ButtonPlaceOrderUpgradeNew?.param?.ButtonPlaceOrderUpgradeNewStatus == 1 ? this.language.SHEIN_KEY_PC_32696 : this.language.SHEIN_KEY_PC_31198
      }
      return this.language.SHEIN_KEY_PC_22336 // continue
    },
    // 获取当前非paypal、venmo等常规按钮的信息
    handleNormalBtnInfo() {
      if (this.paypalSignStatus || ['PayPal-Venmo'].includes(this.status?.cardPay) || (this.applePayBtnShow && this.applePayMethods.includes(this.status?.cardPay))) {
        return
      }

      // 卡路由
      if (this.isShowRouterCardPay) {
        const { web_logo, cardNo, webLogo, card_no  } = this.isShowRouterCardPay
        const placeOrderText = this.placeOrderPayType.includes(placeBtnPayType.CARD_TYPE) ? 
          template(card_no || cardNo, this.language.SHEIN_KEY_PC_31623) :
          this.defaultCheckoutButtonText

        this.nomalBtnLayoutType = normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT
        this.normalBtnData = {
          logo: web_logo || webLogo,
          placeOrderText,
        }
        return
      }

      if (this.isSatisfyBnplHasLure || this.isSatisfyBnplHasNotLure) {
        this.nomalBtnLayoutType = this.isSatisfyBnplHasNotLure ? normalBtnLayoutType.LEFT_TEXT_RIGHT_IMAGE : normalBtnLayoutType.LEFT_IMAGE_RIGHT_TEXT
        this.normalBtnData = {
          placeOrderText: this.handleBNPLPlaceText({ installmentColor: '#FA6338', wordColor: '#fff' }),
          logo: this.currentPayInPayListInfo?.[0]?.logo_url || '',
        }
        return
      } 
      this.nomalBtnLayoutType = normalBtnLayoutType.DEFAULT
      this.normalBtnData = {
        placeOrderText: this.placeOrderTxt, // 按钮文案
        logo: '', // 支付方式的logo
      }
    },
    handleAnalyzeBenefit() {
      if (this.getOrderBtnBenefitData) {
        daEventCenter.triggerNotice({
          daId: '1-11-1-169',
          extraData: {
            scenes: 'innerPlaceorderBenefits',
            inner_benefits_type: this.getOrderBtnBenefitData
          }
        })
      }
    },
  },
  emits: ['createOrder'],
}
</script>
<style lang="less" scoped>
.check-btn{
  padding-top: 0;
  margin-top: 0px;
		button{
			width: 100%;
		}
		.checkout-de span {
			font-size: 15px;
		}
		.venmo-btn{
			background: #3D95CE;
			border-radius: 6px;
			border: none;
			.venmo-btn-img{
				height: 17px;
			}
		}
	}
.checkout-btn-wrapper {
  position: relative;
  .free-shipping-tip {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -4px;
    right: -4px;
    max-width: calc(~"100% + 4px"); // 4px 定位right的值
    padding: 1px 4px;
    background: var(---sui_color_success, #198055);
    /* stylelint-disable-next-line declaration-property-value-blacklist */
    z-index: 101; // 主要解决按钮遮盖问题，当按钮是paypal的时候，层级是100，会出现遮挡
    &__text {
      max-width: calc(~"100% + 31px"); // 31px => 定位right的值4px + 所有的横向间距12px + icon的宽度 15px，获得文本的最大宽度
      height: 13px;
      padding-left: 1px;
      padding-right: 1px;
      margin-left: 4px;
      color: #FFF;
      line-height: 1.1;
      font-weight: 500;
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .italic-text{
      font-style: italic;
    }
  }
  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 13px;
    right: -4px;
    border: 2px solid;
    border-color: #16533A transparent transparent #16533A;
  }
}
.btn-first-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 14px 0;
  &__logo{
    width: 40px;
    height: 26px;
    margin-right: 10px;
    background-color: #FFF;
    border-radius: 4px;
  }
  &__text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 900;
    line-height: 26px;
  }
}
.btn-second-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 14px 0;
  &__logo{
    width: 40px;
    height: 26px;
    margin-left: 10px;
    background-color: #FFF;
    border-radius: 4px;
  }
  &__text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: 900;
    line-height: 26px;
  }
}
.benefit-btn-layout {
  height: 26px;
  line-height: normal;
}
</style>
