<template>
  <div
    class="checkout-prime fsp-element club-product-package-adapter"
    :style="{
      backgroundImage: customStyle.bgImg && `url(${customStyle.bgImg})`,
      '--club-title-color': customStyle.titleColor,
      '--club-subtitle-color': customStyle.subTitleColor,
      '--club-title-place-color': customStyle.titlePlaceColor,
      '--club-protocol-color': customStyle.protocolColor,
      '--club-protocol-hightlight-color': customStyle.protocolHightlightColor
    }"
  >
    <div class="checkout-prime__wrap-header">
      <div
        ref="primeLogoRef"
        class="checkout-prime__logo_new"
      >
        <img
          :src="customStyle.logoImg || primeLogoImg"
          width="96"
          height="18"
        />
      </div>
      <div class="checkout-prime__wrap-tips">
        {{ language.SHEIN_KEY_PC_31292 }}
      </div>
    </div>

    <div
      class="checkout-prime__wrap-des_new"
      :class="{
        'no-support': notSupport,
        'high-light': isHighLightDeduce,
        'single-product': primeProductList.length <= 1
      }"
      @click="viewMore"
      v-html="primeDeduceText"
    ></div>

    <RightPackageList
      :not-support="notSupport"
      :prime-product-list="primeProductList"
      :on-prime-card-click="onPrimeCardClick"
      :auto-renewal-bubble-config="autoRenewalBubbleConfig"
      :on-bubble-close="onBubbleClose"
      :on-offer-label-click="detailDrawerProps.onOfferLabelClick"
      :selectd-prime-index="selectdPrimeIndex"
      :used-prime-index="usedPrimeIndex"
      :pay-method-bubble-props="payMethodBubbleProps"
      @change-active-index="changeActiveIndex"
    />

    <div
      class="checkout-prime__wrap-policy"
      :class="{
        'no-support': notSupport,
        'single-product': primeProductList.length <= 1
      }"
      @click="clickTerm"
    >
      <span v-html="agreeText"></span>
      <ClientOnly>
        <sui_icon_doubt_16px_1
          v-if="activePrime?.sceneResult?.isAutoRenewal"
          size="14px"
          style="cursor: pointer"
          @click.stop="openedRenewalTCDialog = true"
        />
      </ClientOnly>
    </div>
    <div
      v-if="notSupport"
      class="checkout-prime__wrap-tip"
    >
      <sui_icon_reported_14px_1
        size="12px"
        color="#bf4123"
      />
      {{ template(nowPayment.title, language.SHEIN_KEY_PC_22969) }}
    </div>

    <ClientOnly>
      <div v-if="isClient">
        <RightPackageDetailDrawer
          :opened="detailDrawerProps.opened"
          :set-opened="detailDrawerProps.setOpened"
          :prime-product-list="primeProductList"
          :click-term="clickTerm"
          :on-close="detailDrawerProps.onClose"
          :on-ok="detailDrawerProps.onOk"
          :on-offer-label-click="detailDrawerProps.onOfferLabelClick"
          :on-product-select="detailDrawerProps.onProductSelect"
          :auto-renewal-bubble-config="autoRenewalBubbleConfig"
          :on-close-from-icon="detailDrawerProps.onCloseFromIcon"
          :selectd-prime-index="selectdPrimeIndex"
          :used-prime-index="usedPrimeIndex"
          :show-benefit="detailDrawerProps.showBenefit"
          :discount-price="discountPrice"
          :pay-method-bubble-props="payMethodBubbleProps"
        />

        <!-- 自动续费协议弹窗 -->
        <RenewalTCDialog
          :visible="openedRenewalTCDialog"
          :content="
            template(
              activePrime?.product_price_info?.subscription_price_with_symbol,
              activePrime?.product_cycle_days,
              language.SHEIN_KEY_PC_29426
            )
          "
          :active-prime="activePrime"
          @close-dialog="openedRenewalTCDialog = false"
        />

        <s-dialog
          v-if="discountDialogProps.priceInfo"
          :visible="discountDialogProps.opened"
          show-close
          @update:visible="
            value =>
              setDiscountDialogProps({
                ...discountDialogProps,
                opened: value
              })
          "
        >
          <template #icon>
            <img
              :src="discountDialogHeaderBg"
              class="header-bg"
            />
          </template>
          <template #title>
            <div class="header-bg-container">
              {{ language.SHEIN_KEY_PC_28842 }}
              <div class="pickColor_prime xtra-text">
                {{ discountDialogProps.priceInfo.reduce_price_with_symbol }}
              </div>
            </div>
          </template>
          <template #footer>
            <div
              v-if="discountDialogData.length"
              class="virXrt_container"
            >
              <div
                v-for="item in discountDialogData"
                :key="item.rightValue"
                class="virXrt_line"
                :class="{ 'virXrt_take-price': item.isTakePrice }"
              >
                <div class="virXrt_line-left">
                  <span style="margin-right: 5px">{{ item.leftText }}</span>
                  <Icon
                    v-if="item.isHasIcon"
                    name="sui_icon_doubt_16px_1"
                    size="16px"
                    @click="clickDiscountTips"
                  />
                </div>
                <div
                  v-if="item.isSave"
                  class="pickColor_prime"
                >
                  - {{ item.rightValue }}
                </div>
                <div v-else>
                  {{ item.rightValue }}
                </div>
              </div>
            </div>
            <s-button
              :type="['primary']"
              width="100%"
              @click="
                setDiscountDialogProps({
                  ...discountDialogProps,
                  opened: false
                })
              "
            >
              {{ language.SHEIN_KEY_PC_28851 }}
            </s-button>
          </template>
        </s-dialog>

        <s-dialog
          :visible="limitModalProps.opened"
          :show-close="true"
          append-to-body
          class="prime-limit__pop"
          @close="limitModalProps.setOpened(false)"
        >
          <div>
            {{ language.SHEIN_KEY_PC_27377 }}
          </div>
          <template #footer>
            <s-button
              :type="['H44PX', 'primary']"
              :width="'80%'"
              @click="limitModalProps.setOpened(false)"
            >
              {{ language.SHEIN_KEY_PC_15216 }}
            </s-button>
          </template>
        </s-dialog>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { template as _commonTemplate } from '@shein/common-function'
import RightPackageList from './RightPackageList.vue'
import {
  Icon,
  sui_icon_doubt_16px_1,
  sui_icon_reported_14px_1
} from '@shein-aidc/icon-vue3'
import { transformPriceSymbol } from 'public/src/pages/common/utils/index.js'
import { getProductStyle } from '../shared/utils'

const { currency, currencies } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

// prime_options_new.vue
export default {
  name: 'ClubProductPackage',
  components: {
    Icon,
    sui_icon_doubt_16px_1,
    sui_icon_reported_14px_1,
    RightPackageList,
    RightPackageDetailDrawer: defineAsyncComponent(() =>
      import('./RightPackageDetailDrawer.vue')
    ),
    RenewalTCDialog: defineAsyncComponent(() => import('./RenewalTCDialog.vue'))
  },
  inheritAttrs: false,
  props: {
    selectedPrime: {
      type: Object,
      default: null
    },
    notSupport: {
      type: Boolean,
      default: false
    },
    nowPayment: {
      type: Object,
      default: null
    },
    primeProductList: {
      type: Array,
      default: () => []
    },
    onPrimeCardClick: {
      type: Function,
      default: () => {}
    },
    viewMore: {
      type: Function,
      default: () => {}
    },
    clickTerm: {
      type: Function,
      default: () => {}
    },
    limitModalProps: {
      type: Object,
      default: () => ({
        opened: false,
        /** @type {(value: boolean) => void} */
        setOpened: () => {}
      })
    },
    showScribePrice: {
      type: Boolean,
      default: false
    },
    savingSummary: {
      type: Object,
      default: null
    },
    language: {
      type: Object,
      default: () => ({})
    },
    detailDrawerProps: {
      type: Object,
      default: () => ({
        opened: false,
        /** @type {(value: boolean) => void} */
        setOpened: () => {},
        showBenefit: false,
        onOk: () => {},
        onClose: () => {},
        onOfferLabelClick: () => {},
        onProductSelect: () => {},
        onCloseFromIcon: () => {}
      })
    },
    discountDialogProps: {
      type: Object,
      default: () => ({
        opened: false,
        priceInfo: null
      })
    },
    setDiscountDialogProps: {
      type: Function,
      default: () => {}
    },
    historyOmitAmountText: {
      type: String,
      default: ''
    },
    autoRenewalBubbleConfig: {
      type: Array,
      default: () => []
    },
    onBubbleClose: {
      type: Function,
      default: () => {}
    },
    usedPrimeIndex: {
      type: Number,
      default: -1
    },
    selectdPrimeIndex: {
      type: Number,
      default: -1
    },
    discountPrice: {
      type: Object,
      default: () => ({})
    },
    payMethodBubbleProps: {
      type: Object,
      default: null
    },
    eventCenterConfig: {
      type: Object,
      default: () => ({})
    },
    styleConfig: {
      type: Object,
      default: () => ({})
    },
    styleType: {
      type: String,
      default: 'old'
    },
    locals: {
      type: Object,
      default: () => ({})
    },
    // 付费会员返现预算金额
    primeCreditReward: {
      type: [Number, String],
      default: 0
    },
    // 付费会员预算总优惠
    primeTrialTotalDiscountPrice: {
      type: Object,
      default: () => ({})
    },
    freeGiftInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isHighLightDeduce: false,
      openedRenewalTCDialog: false,
      // 当前主屏幕上的产品包
      activePrime: {},
      isClient: false,
      // shein club 回本滚动金额
      primeDeducePriceWithAnimation: {},
    }
  },
  provide() {
    return {
      language: this.language,
      historyOmitAmountText: this.historyOmitAmountText,
      showScribePrice: this.showScribePrice,
      eventCenterConfig: this.eventCenterConfig,
      styleConfig: this.styleConfig,
      styleType: this.styleType,
      PUBLIC_CDN: this.PUBLIC_CDN,
      langPath: this.langPath,
      SiteUID: this.SiteUID,
      GB_cssRight: this.GB_cssRight,
      freeGiftInfo: this.freeGiftInfo
    }
  },
  computed: {
    PUBLIC_CDN() {
      return this.locals.PUBLIC_CDN
    },
    GB_cssRight() {
      return this.locals.GB_cssRight
    },
    langPath() {
      return this.locals.langPath
    },
    SiteUID() {
      return this.locals.SiteUID
    },
    discountDialogHeaderBg() {
      return `${this.PUBLIC_CDN}/she_dist/images/checkout/prime_header_bg-1f2c24223a.png`
    },
    discountDialogData() {
      const priceInfo = this.discountDialogProps.priceInfo || {}
      const originText =
        this.SiteUID === 'de'
          ? this.language.SHEIN_KEY_PC_31129
          : this.language.SHEIN_KEY_PC_30490
      let positiveValue = this.showScribePrice
        ? [
          {
            leftText: originText,
            rightValue: priceInfo.price_local_with_symbol
          }, // 原价
          {
            leftText: this.language.SHEIN_KEY_PC_30553,
            rightValue: priceInfo.special_price_with_symbol,
            isTakePrice: true
          } // 到手价
        ]
        : []
      // 活动优惠
      const isShowDialogDiscount =
        (this.showScribePrice && priceInfo._discountPriceAmount > 0) ||
        !this.showScribePrice
      if (isShowDialogDiscount) {
        const discountPrice = {
          leftText: this.language.SHEIN_KEY_PC_28843,
          rightValue: priceInfo._discountPrice,
          isHasIcon: true,
          isSave: true
        }
        positiveValue.length
          ? positiveValue.splice(1, 0, discountPrice)
          : positiveValue.push(discountPrice)
      }

      // 会费券优惠
      const isShowDialogCounpon =
        (this.showScribePrice && priceInfo._conponPriceAmount > 0) ||
        !this.showScribePrice
      if (isShowDialogCounpon) {
        const conponPrice = {
          leftText: this.language.SHEIN_KEY_PC_28844,
          rightValue: priceInfo._conponPrice,
          isSave: true
        }
        positiveValue.length > 1
          ? positiveValue.splice(positiveValue.length - 1, 0, conponPrice)
          : positiveValue.push(conponPrice)
      }

      return positiveValue
    },
    primeLogoImg() {
      return this.GB_cssRight
        ? 'https://img.ltwebstatic.com/images3_ccc/2024/06/13/a9/1718269813b098aeac5ed901365e244b55f1d6a668.webp'
        : 'https://img.ltwebstatic.com/images3_ccc/2024/06/13/54/171826806807a1e3dbaa9aec956261587581a39dee.webp'
    },
    primeSavingSummaryInfo() {
      const savingSummary = this.savingSummary || {}
      const summaryInfo = {
        amount: savingSummary.total_saving_amount_currency || '',
        amountWithSymbol:
          savingSummary.total_saving_amount_currency_with_symbol || '',
        usdAmount: savingSummary.total_saving_amount_usd || '',
        usdAmountWithSymbol:
          savingSummary.total_saving_amount_usd_with_symbol || ''
      }
      return summaryInfo
    },
    primeDeduceText() {
      const {
        SHEIN_KEY_PC_27358, // save {0} after joining >
        SHEIN_KEY_PC_27359, // Cost {0} per day to join >
        SHEIN_KEY_PC_27360, // Saved {0} in total >
        SHEIN_KEY_PC_28811, // Already saved {0} on this order!
        SHEIN_KEY_PC_28812, // Cost {0} per day to join!
        SHEIN_KEY_PC_32468, // 有省额，勾选后回本 (不含credit): You've saved {0} ! Cost recovered
        SHEIN_KEY_PC_32469, // 有省额，勾选前: Save {0} on this order, including credit >
        SHEIN_KEY_PC_32470, // 有省额，勾选后未回本: You've saved {0} , including credit ! 
        SHEIN_KEY_PC_32471, // 有省额，勾选后回本: You've saved {0} , including credit ! Cost recovered
      } = this.language
      const special_price =
        this.selectedPrime?.product_price_info?.special_price || 0
      const average_price =
        this.selectedPrime?.product_price_info
          ?.average_daily_price_with_symbol || 0
      const primeDeducePrice = this.usedPrimeIndex >= 0 ? this.discountPrice : this.primeTrialTotalDiscountPrice

      // 需要做勾选后文案的更改
      if (this.usedPrimeIndex >= 0) {
        if (+primeDeducePrice?.amount > 0) {
          if (+primeDeducePrice?.amount < +special_price) {
            // 本单可省金额＜开通付费会员金额
            this.handleHighLight(true)
            return this.template(
              '<i class="prime-deduce">' +
                primeDeducePrice.amountWithSymbol +
                '</i>',
              +this.primeCreditReward ? SHEIN_KEY_PC_32470 : SHEIN_KEY_PC_28811
            )
          } else {
            // 本单可省金额＞=开通付费会员金额
            this.handleHighLight(false)
            return this.template(
              '<i class="prime-deduce prime-deduce-animation">' + primeDeducePrice.amountWithSymbol + '</i>',
              +this.primeCreditReward ? SHEIN_KEY_PC_32471 : SHEIN_KEY_PC_32468
            )
          }
        } else {
          // 本单无可省金额
          this.handleHighLight(true)
          return this.template(
            '<i class="prime-deduce">' + average_price + '</i>',
            SHEIN_KEY_PC_28812
          )
        }
      }

      if (+primeDeducePrice?.amount > 0) {
        if (+primeDeducePrice?.amount < +special_price) {
          // 本单可省金额＜开通付费会员金额
          this.handleHighLight(true)
          // 存在历史节省金额
          if (
            +this.primeSavingSummaryInfo.amount > 0 &&
            this.primeSavingSummaryInfo.amountWithSymbol
          )
            return this.template(
              '<i class="prime-deduce">' +
                this.primeSavingSummaryInfo.amountWithSymbol +
                '</i>',
              SHEIN_KEY_PC_27360
            )
          return this.template(
            '<i class="prime-deduce">' +
              primeDeducePrice.amountWithSymbol +
              '</i>',
            +this.primeCreditReward ? SHEIN_KEY_PC_32469 : SHEIN_KEY_PC_27358
          )
        } else {
          // 本单可省金额＞=开通付费会员金额
          this.handleHighLight(false)
          return this.template(
            '<i class="prime-deduce">' +
              primeDeducePrice.amountWithSymbol +
              '</i>',
            +this.primeCreditReward ? SHEIN_KEY_PC_32469 : SHEIN_KEY_PC_27358
          )
        }
      } else {
        // 本单无可省金额
        this.handleHighLight(true)
        return this.template(
          '<i class="prime-deduce">' + average_price + '</i>',
          SHEIN_KEY_PC_27359
        )
      }
    },
    agreeText() {
      const arcId = this.activePrime?.article_page_id
      let agreeText = this.template(
        `${this.langPath}/SHEIN-CLUB-TERMS-AND-CONDITIONS-a-${arcId}.html`,
        this.language.SHEIN_KEY_PC_27378?.replace('color: #2D68A8;', '')
      )
      return agreeText
    },
    customStyle() {
      const activeProductCode = this.activePrime?.product_code

      return {
        ...this.styleConfig.productPackageStyle?.basicConfig,
        ...getProductStyle(
          activeProductCode,
          this.styleConfig.productPackageStyle?.productPackageConfigList,
          this.styleConfig.productPackageStyle?.productPackageConfig
        )
      }
    }
  },
  watch: {
    primeDeduceText(newVal) {
      if(newVal.includes('prime-deduce-animation')) {
        this.startPriceRolling()
      }
    }
  },
  mounted() {
    this.isClient = true
    setTimeout(() => this.playAnimation(), 1000)
  },
  methods: {
    template: _commonTemplate,
    handleHighLight(value) {
      this.isHighLightDeduce = value
    },
    clickDiscountTips() {
      this.limitModalProps.setOpened(true)
    },
    changeActiveIndex(index) {
      this.activePrime = this.primeProductList[index]
    },
    startPriceRolling() {
      this.$nextTick(() => {
        const el = document.querySelector('.prime-deduce-animation')
        this.primeDeducePriceWithAnimation = this.discountPrice
        this.handleTotalPriceAnimate({ ...this.primeDeducePriceWithAnimation }, { amount: '0.00' }, (newTotalPrice) => {
          if(el) {
            el.textContent = newTotalPrice?.amountWithSymbol
          }
        })
      })
    },
    handleTotalPriceAnimate(newTotalPrice, oldTotalPrice, callback) {
      if (typeof window === 'undefined') {
        callback(newTotalPrice)
        return
      }
      if (isNaN(newTotalPrice.amount) || isNaN(oldTotalPrice.amount)) {
        return
      }
      let currencyRule = currencies?.[currency] || {}
      const decimal_place = currencyRule.decimal_place
      const isAdd = +newTotalPrice.amount > +oldTotalPrice.amount
      // 完成动画花费的时长
      const duration = 300
      // 一帧的时长
      const TIME_PER_FRAME = 16.7
      // 动画帧数
      const frameCount = duration / TIME_PER_FRAME
      const priceDiff = newTotalPrice.amount - oldTotalPrice.amount
      // 每一帧的价格步长
      const priceStep = +(priceDiff / frameCount).toFixed(decimal_place)
      let currentPrice = +oldTotalPrice.amount
      const isAnimate = true
      let timer = setInterval(function () {
        if (isAnimate) {
          // 防止步长为0时导致死循环
          if (priceStep == 0) {
            currentPrice = +newTotalPrice.amount
            oldTotalPrice = newTotalPrice
          } else {
            currentPrice += priceStep
            if (isAdd) {
              if (currentPrice >= +newTotalPrice.amount) {
                currentPrice = +newTotalPrice.amount
                oldTotalPrice = newTotalPrice
              } else {
                oldTotalPrice = { ...oldTotalPrice, amount: currentPrice, amountWithSymbol: transformPriceSymbol(currentPrice, '', currencyRule) }
              }
            } else {
              if (currentPrice <= +newTotalPrice.amount) {
                currentPrice = +newTotalPrice.amount
                oldTotalPrice = newTotalPrice
              } else {
                oldTotalPrice = { ...oldTotalPrice, amount: currentPrice, amountWithSymbol: transformPriceSymbol(currentPrice, '', currencyRule) }
              }
            }
          }
        }
        callback && callback(oldTotalPrice)
        if (currentPrice == newTotalPrice.amount) {
          clearInterval(timer)
          timer = null
        }
      }, TIME_PER_FRAME)
    },
    playAnimation() {
      if (typeof window == 'undefined') return
      const logo = this.$refs.primeLogoRef
      const logoHeight = logo.getBoundingClientRect().height
      // 白色的动效条需要倾斜15度
      const radian = 15 * (Math.PI / 180)
      const stripHeight = logoHeight / Math.cos(radian)
      const strip = document.createElement('span')
      strip.className = 'logo-strip'
      strip.style.height = `${stripHeight}px`
      logo.appendChild(strip)

      setTimeout(() => {
        document.querySelector('.logo-strip')?.remove()
      }, 1500)
    }
  }
}
</script>

<style lang="less">
.pickColor_prime {
  color: #fa6338;
}
// 擦亮动画
@keyframes prime-strip-move {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% + 0.2667rem);
  }
}

@keyframes prime-strip-move-rtl {
  0% {
    right: 0;
  }
  100% {
    right: calc(100% + 0.2667rem);
  }
}
.checkout-prime {
  background: #fff7ed;
  margin: 10px 0;
  padding-bottom: 12px;
  background-size: 100% 100%;

  .checkout-prime__wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .checkout-prime__logo {
      height: 39px;
      padding-left: 8px;
      display: flex;
      align-items: center;
      position: relative;
      background-image: url(/she_dist/images/checkout/prime_title_bg-41fe6fc05a.png);
      background-size: 100% 100%;
      &:after {
        content: '';
        width: 20px;
        height: 39px;
        position: absolute;
        right: -20px;
        bottom: 0;
        background-image: url(/she_dist/images/checkout/prime_title_bg_triangle-f4c3e8f08c.png);
        background-size: 100% 100%;
      }
    }
    .checkout-prime__logo_ar {
      &:after {
        background-image: url(/she_dist/images/checkout/prime_title_bg_triangle_ar-64c3a2675f.png);
      }
    }

    .checkout-prime__logo_new {
      position: relative;
      width: calc(96px - 0.2667rem);
    }

    .logo-strip {
      width: 8px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        95deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.1)
      );
      transform: rotate(15deg);
      animation: prime-strip-move 0.5s 3;
    }
  }
  // 旧logo样式
  .checkout-prime__wrap-logo {
    width: 117px;
    height: 26px;
  }

  .checkout-prime__wrap-tips {
    color: var(--club-subtitle-color, @sui_color_club_rosegold_dark1);
    text-align: right;
    font-family: 'SF UI Text';
    font-size: 12px;
    margin: 4px 12px 0 0;
    white-space: nowrap;
    max-width: 252px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .high-light {
    padding-top: 4px;
  }

  .checkout-prime__wrap-des_new {
    max-width: calc(100% - 24px);
    padding: 0 12px;
    margin: 6px 0 -6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    color: var(--club-title-color, #d17000);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .prime-deduce {
      color: var(--club-title-place-color, #fb4e1d);
      font-style: normal;
    }

    &.single-product {
      padding-left: 24px;
    }
  }

  .checkout-prime__wrap-policy {
    padding: 0 12px;
    color: var(--club-protocol-color, @sui_color_gray_light1);
    font-size: 10px;
    font-weight: 400;

    a {
      text-decoration: none;
      color: var(--club-protocol-hightlight-color, #2d68a8);
    }

    &.single-product {
      padding-left: 24px;
    }
  }

  .checkout-prime__wrap-tip {
    padding: 0 12px;
    color: @sui_color_unusual;
    font-size: 12px;
    font-weight: 400;
  }

  .no-support {
    opacity: 0.3;
  }
}

.header-bg {
  position: absolute;
  top: 0;
  left: 0px;
}
.header-bg-container {
  position: relative;
  margin-top: -20px;
  font-size: 16px;
  .xtra-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.7;
  }
}

.virXrt_container {
  margin-bottom: 36px;
  font-size: 14px;
  .virXrt_line {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .virXrt_line-left {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .virXrt_take-price {
    font-weight: 600;
  }
}

.prime-limit__pop {
  text-align: center;
}
</style>
